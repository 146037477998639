@import 'shared/styles/global';

.card {
	border: 1px solid $c-lighter-grey;
	border-radius: 4px;

	transition: $transition;
	transition-property: box-shadow;

	&Open,
	&:hover {
		box-shadow: $box-shadow-s;
	}
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: spacing(3);

	&Left {
		display: flex;
		gap: spacing(3);
		align-items: center;
	}

	&Title {
		margin-bottom: spacing(1);
	}

	&Button {
		&Open {
			padding-right: spacing(2);
		}

		&Close {
			padding: spacing(1);

			color: $c-dark-grey;

			transform: rotate(180deg);
		}
	}
}

.body {
	display: grid;
	grid-template-rows: 0fr;

	transition: $transition;

	transition-property: grid-template-rows;

	& > div {
		overflow: hidden;
	}

	&Open {
		grid-template-rows: 1fr;
	}
}
