@import "shared/styles/global";

$controls-height: 68px;

.container {
	border-right: solid 1px $c-grey;
}

.header {
	display: flex;
	justify-content: space-between;
	padding: spacing(2, 1, 2, 2);
	border-bottom: solid 1px $c-grey;

	& > div {
		display: flex;
		gap: 10px;
	}

	&Selection {
		gap: spacing(2);
	}
}

.create {
	padding: spacing(1);
}

.list {
	height: calc(100vh - $controls-height);
	padding-top: spacing(1.5);
	overflow: auto;
}
