@keyframes blink {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.placeholder {
	&::after {
		animation: blink .5s linear infinite alternate;

		content: '|';;
	}
}
