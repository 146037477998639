.divider {
	width: 100%;
	height: 1px;

	background: #D9D9D9;

	&Wrapper {
		display: flex;
		gap: 10px;
		align-items: center;
		width: 100%;
	}
}
