@import "shared/styles/global";

$header-height: 121px;

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: spacing(2, 3, 2, 1);

	&Left {
		display: flex;
		gap: spacing(1);
		align-items: center;
	}

	&Button {
		padding: spacing(1);

		transform: rotate(90deg);
	}

	&Title {
		padding-top: 3px;

		text-transform: uppercase;
	}
}

.controls {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: spacing(2, 3);

	background-color: white;
}

.table {
	height: calc(100vh - $header-height);
	overflow-y: scroll;

	&Header {
		position: sticky;
		top: 0;
		z-index: 10;

		border-bottom: 1px solid #D9D9D9;
	}
}

.expiration {
	display: flex;
	gap: spacing(2);
	align-items: center;
	margin: 10px;
	padding: spacing(1, 2);
	border-radius: 2px;

	background: #DDEBFF;

	&Icon {
		color: $c-light-blue;
	}
}

.footer {
	position: relative;

	display: flex;

	align-items: center;

	justify-content: center;

	height: 60px;
	padding: spacing(0, 3);

	&Pagination {
		position: absolute;
		top: 18px;
		right: 84px;

		margin-left: auto;
	}
}
