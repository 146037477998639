@import "shared/styles/global";

.tooltip {
	max-width: 195px;

	&Credits {
		color: $c-success;
	}
}

.contacts {
	display: flex;
	flex-direction: column;
	gap: 10px;
	min-width: 287px;
	height: fit-content;
	min-height: 74px;
	margin-left: auto;
	padding: 12px;
	border-radius: 2px;

	background-color: #f3f3f3;

	&Open {
		border-left: solid 1px $c-lighter-grey;

		background-color: white;
	}

	&Closed {
		display: flex;
		flex-direction: column;
		gap: spacing(1);
		min-width: 285px;
		height: fit-content;
		margin-left: auto;
		padding: 12px;
		border-radius: 4px;

		background-color: #f3f3f3;
	}

	&Mail {
		display: flex;
		gap: spacing(1);

		color: $c-dark-grey;
	}


	&List {
		text-align: left;

		list-style: none;

		li {
			display: flex;
			align-items: center;
			
			&:not(:last-child) {
				margin-bottom: spacing(1);
			}
		}

		&Primary span {
			font-weight: 600;
			font-size: 15px;
		}
	}

	&Social {
		display: flex;
		gap: spacing(1);

		color: $c-dark-grey;

		a {
			height: 20px;
		}
	}
}

.icon {
	&Github {
		color: black;

		&:hover, &:active {
			color: black;
		}
	}

	&Linkedin {
		color: $c-linkedin;

		&:hover, &:active {
			color: $c-linkedin;
		}
	}

	&Facebook {
		color: $c-facebook;

		&:hover, &:active {
			color: $c-facebook;
		}
	}

	&Twitter {
		color: black;

		&:hover, &:active {
			color: black;
		}
	}

	&Website {
		color: $c-light-blue;

		&:hover, &:active {
			color: $c-light-blue;
		}
	}

	&Mail {
		color: $c-dark-grey;
	}
}

.email {
	color: $c-light-blue;

	filter: blur(6px);

	--webkit-user-select: none;
	--ms-user-select: none;
	user-select: none;

	&Wrapper {
		display: flex;
		gap: spacing(1);

		align-items: center;
	}
}

.more {
	display: flex;
	justify-content: center;
}
