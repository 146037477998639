@import "shared/styles/global";

$width-left-panel: 360px;
$width-right-panel: 327px;
$width-margins: 76px;

.info {
	display: flex;
	flex-direction: column;
	gap: spacing(2);

	&Items {
		display: flex;
		flex-direction: column;
		gap: spacing(2);
		width: calc(100vw - $width-left-panel - $width-right-panel - $width-margins);
		margin-bottom: spacing(1);
		padding: spacing(0, 2);

		&Shared {
			display: flex;
			flex-direction: column;
			gap: spacing(2);
			width: calc(1075px - $width-right-panel - $width-margins);
			margin-bottom: spacing(1);
			padding: spacing(0, 2);
		}
	}

	&Matched {
		display: flex;
		flex-direction: column;
		gap: spacing(1);
		padding: spacing(0, 2);
	}

	&Highlights {
		display: flex;
		gap: spacing(2);
		margin-bottom: spacing(1);
		padding-left: spacing(2);

		&Item {
			display: flex;
		}
	}
}

.topExpertWrapper {
	display: flex;
	gap: 8px;
	align-items: flex-start;
	width: fit-content;
	padding: 12px 16px;

	border-radius: 4px;

	background: #F8F8FB;
}

.column {
	display: flex;
	flex-direction: column;
}

.emoji {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 16px;
	margin-right: spacing(1);

	font-size: 24px;
}

.header {
	display: flex;
	justify-content: space-between;
	padding: 12px 0;
}

.row {
	display: flex;
	gap: spacing(1);
	align-items: center;
}

.icon {
	flex-shrink: 0;

	color: $c-grey-profile;
}

.education {
	display: flex;
	flex-direction: row;
}

.rate {
	display: flex;
	flex-direction: row;
	gap: spacing(0.5);
	align-items: center;

	height: 24px;

	padding-left: 16px;

	border-left: 1px solid #D9D9D9;

	&Icon {
		z-index: 5;

		height: 36px;

		padding: spacing(1);

		cursor: pointer;

		&:hover{
			background-color: #F3F3F3;
		}
	}
}
