@import "shared/styles/global";

.buttons {
	display: flex;
	gap: spacing(3);
}

.form {
	max-width: 878px;

	&Item {
		display: flex;
		gap: spacing(3);

		&Label {
			min-width: 200px;
		}

		div {
			width: 100%;
		}
	}
}

.info {
	display: flex;
	flex-direction: column;
	gap: spacing(3);

	padding-bottom: spacing(1);
}

.email {
	padding: spacing(2, 0);
}

.logout {
	position: absolute;
	bottom: spacing(10);

	width: 204px;
}
