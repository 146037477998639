@import "shared/styles/global";

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 878px;
	height: 68px;
	margin-bottom: spacing(4);
	border-bottom: solid 1px $c-grey;
}
