@import "shared/styles/global";

.dropdown {
	width: 230px;

	border: 1px solid $c-lighter-grey;
	border-radius: 2px;

	background: white;

	box-shadow: $box-shadow-s;

	&Trigger {
		padding: spacing(1);

		color: $c-dark-grey;
	}
}

.button {
	display: flex;
	gap: spacing(1);
	align-items: center;
	width: 100%;
	padding: spacing(1.5);

	background-color: white;
}
