@import 'shared/styles/global';

.wrapper {
	max-width: 878px;
	margin: 40px auto;
}

.description {
	margin-top: spacing(1);
}

.list {
	display: flex;
	flex-direction: column;
	gap: spacing(2);
	margin-top: spacing(3);
}

.coming {
	margin-top: spacing(7.5);
}
