@import "shared/styles/global";

.card {
	margin: 42px auto;

	text-align: center;
}

.icon {
	margin: 0 auto;

	color: $c-red-error;
}

.info {
	display: flex;
	flex-direction: column;
	gap: 20px;
}
