@import 'shared/styles/global';

.header {
	display: flex;
	gap: spacing(2);
	align-items: center;
}

.logo {
	display: flex;
	align-items: center;

	img {
		width: 32px;
		height: 32px;
	}
}

.body {
	display: flex;
	flex-direction: column;
	gap: spacing(1);
}

.icon {
	color: $c-dark-grey;
}

.variants {
	display: flex;
	flex-direction: column;
	gap: 2px;
}

.group {
	margin-bottom: 0;
}

.list {
	max-height: 160px;
	padding: spacing(1, 2);
	border-radius: 4px;
	overflow-y: auto;

	background-color: $c-light-grey;
}

.link {
	color: $c-link-blue;
}
