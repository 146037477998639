@import "shared/styles/global";

.link {
	display: flex;
	gap: spacing(2);
	padding: spacing(1.5, 2);

	text-decoration: none;

	&Active {
		border-right: solid 4px $c-bright-green;

		background: $c-very-light-green;
	}
}

.checkbox {
	align-self: start;

	visibility: visible;
	opacity: 1;

	:global(.ant-checkbox-inner) {
		background-color: transparent;
	}
}

.title {
	margin-bottom: spacing(1);
}
