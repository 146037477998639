@import 'shared/styles/global';

$c-button-disabled: #999;

.wrapper {
    position: relative;

    display: flex;

    gap: spacing(2);

    align-items: center;

    justify-content: center;

    width: 100%;
    height: 48px;

    border-radius: 8px;

    font-family: $f-inter;

    text-align: start;

    text-decoration: none;

    background: black;

    &:disabled {
        border-color: $c-button-disabled;

        color: $c-button-disabled;

        filter: grayscale(1);
    }

    &:hover {
        background: #1E78FF;
    }
}

.icon {
    padding: 6px;

    border-radius: 4px;

    background-color: #FFF;
}
