@import "shared/styles/global";

.tabs {
	&Wrapper {
		position: relative;

		gap: spacing(1);

		width: fit-content;
		margin: 0 auto;
	}

	&Save {
		margin-top: spacing(1);
		margin-right: spacing(16);

		opacity: 0.6;
	}
}


.cards {
	display: grid;
	grid-template-rows: 1fr;
	grid-template-columns: repeat(3, 1fr);
	gap: spacing(3);
	justify-content: center;

	// margin-top: spacing(-3);

	&Wrapper {
		display: flex;
		flex-direction: column;
		gap: spacing(5);

		text-align: center;
	}
}

.card {
	display: flex;
	flex-direction: column;
	gap: spacing(5);
	width: 100%;
	min-width: 260px;
	padding: spacing(4, 3);

	&Top {
		min-height: 70px;
	}

	&Description {
		min-height: 6em;

		text-align: start;
	}

	&Perks {
		display: flex;
		flex-direction: column;
	}

	&Gradient {
		border: solid 2px transparent;
		border-radius: 4px;

		&Before {
			margin: -2px;

			background: linear-gradient(
					to bottom right,
					rgba($c-primary-gradient-left, 0.4),
					rgba($c-primary-gradient-right, 0.4)
			);
		}
	}

	&Price {
		display: flex;
		gap: spacing(1);
		align-items: center;
		min-height: 38px;

		@include laptop {
			flex-direction: column;
			align-items: flex-start;
		}

		&Custom {
			@include laptop {
				margin-bottom: 22px;
			}
		}

		&Additional {
			margin-top: 8px;
		}
	}

	ul {
		list-style: none;

		li {
			display: flex;
			gap: spacing(1);
			align-items: center;

			text-align: start;

			img {
				width: 20px;
				height: 20px;
			}

			&:not(:last-child) {
				margin-bottom: spacing(2);
			}

			span {
				font-weight: 700;
			}
		}
	}

	&Pro {
		background-color: $c-very-light-green;
	}
}

.blocked {
	color: $c-dark-grey;
}

.contact {
	text-align: center;

	a {
		color: black;
	}
}

.expired {
	display: flex;
	gap: spacing(2);
	margin-top: spacing(-3);
	padding: spacing(2);

	border-radius: 2px;

	text-align: center;

	background-color: $c-orange-light;

	span {
		font-weight: 500;
	}
}

.orange {
	color: #fb8b35;
}

.bottomButton {
	position: absolute;
	bottom: spacing(4);
	left: spacing(3);

	width: calc(100% - 48px);
}
