@import "shared/styles/global";

.link {
	display: flex;
	gap: spacing(2);
	padding: spacing(1.5, 2);

	text-decoration: none;

	&Icon {
		color: $c-light-blue;
	}

	&Text {
		display: flex;
		flex-direction: column;
		gap: spacing(1);

		text-transform: capitalize;
	}

	&Active {
		border-right: solid 4px $c-light-blue;

		background: #DDEBFF;
	}
}
