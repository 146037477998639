@import "shared/styles/global";

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.spin {
	margin-right: 16px;
	margin-left: 16px;

	animation: spin 1.5s linear infinite;
}