@import 'shared/styles/global';

.header {
	display: flex;
	padding: 6px  24px 6px 6px;
	border-bottom: solid 1px $c-grey;

	background-color: #F8F8FB;

	&Section {
		display: flex;
		gap: spacing(1);
		align-items: center;
		width: 100%;

		&Right {
			display: flex;
			gap: spacing(1);
			align-items: center;
			margin-left: auto;
		}
	}
}

.back {
	height: 40px;
}

.divider {
	height: 32px;
	margin-top: 3px;

	&First{
		margin-right: spacing(1);
	}
}

.button {
	padding: spacing(1);

	&:hover {
		background-color: #EBEDF0;
	}

	&Contact {
		position: relative;
	}

	&Project {
		padding: spacing(1,1,1,3);
	}

	&Disabled {
		opacity: 50%;

		pointer-events: none;
	}

	&Unlock {
		padding: spacing(1, 3);
	}

	&Find {
		padding: spacing(1, 3);

		border: 1px solid #D9D9D9;
		border-radius: 2px;

		background-color: transparent;

		&:hover {
			background-color: #EBEDF0;
		}
	}
}

.icon {
	color: $c-dark-grey;

	&Credits {
		position: absolute;
		right: 4px;
		bottom: 4px;

		color: #c0c2c1;
	}

	&Previous {
		transform: rotate(180deg);
	}
}
