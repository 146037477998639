@import 'shared/styles/global';

.info {
	display: flex;
	gap: 24px;
	padding: 24px;
	border-bottom: solid 1px $c-grey;

	&Data {
		flex-grow: 1;
	}

	&Top {
		margin-bottom: 47px;
	}

	&Name {
		display: flex;
		gap: 8px;
		margin-bottom: 8px;
	}

	&Bottom {
		display: flex;
		justify-content: space-between;
	}

	&Icons {
		display: flex;
		gap: 12px;
	}
}

.skeleton {
	display: grid;
	grid-template-columns: 1fr 263px;
	height: 100vh;
}

.sections {
	padding: 0 24px;
}

.section {
	padding: 14px 0 24px;
	border-bottom: solid 1px $c-lighter-grey;

	&Title {
		margin-bottom: 23px;
	}

	&Text {
		margin-bottom: 4px;
		margin-left: 16px;
	}

	&Button {
		margin-left: auto;
	}
}

.about {
	display: flex;
	margin-left: 16px;

	&Side {
		&:last-child {
			width: 100%;
			margin-left: 16px;
		}

		& > div:not(:last-child) {
			margin-bottom: 4px;
		}
	}

	&Title {
		margin-bottom: 15px;
	}

	&Width {
		min-width: 122px;
	}

	&Row {
		display: flex;
		gap: 4px;
	}
}

.profile {
	border-right: solid 1px $c-grey;
}

.skills {
	&Title {
		margin-bottom: 38px;
	}

	&Programming {
		display: flex;
		flex-wrap: wrap;
		gap: spacing(1);
	}

	&Section {
		padding: 14px 16px;

		&Programming {
			padding-bottom: 23px;
			border-bottom: solid 1px $c-grey;
		}
	}
}

.skill {
	margin-bottom: 16px;

	&Title {
		margin-bottom: 8px;
	}

	&Progress {
		li {
			height: 10px;
			min-height: 10px;
		}
	}
}
