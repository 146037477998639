@import "shared/styles/global";

.link {
	color: $c-link-blue;
}

.logo {
	width: 40px;
	height: 40px;
}

.body {
	display: flex;
	flex-direction: column;
	padding: spacing(0, 3, 3);
}

.text {
	margin-bottom: spacing(1);

	&:last-child {
		margin-bottom: spacing(3);
	}
}

.actions {
	display: flex;
	justify-content: end;
}
