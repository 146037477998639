@import "shared/styles/global";

.layout {
	display: grid;
	grid-template:
		'header header' 72px
		'navbar content' auto;
	grid-template-columns: 228px auto;
	height: 100%;
}

.header {
	grid-area: header;
}

.navbar {
	grid-area: navbar;
}

.content {
	grid-area: content;
	padding-left: 20px;

	background-color: $c-very-light-grey;
}
