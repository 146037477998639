@import 'shared/styles/global';

.formNew {
	align-self: center;
	width: 100%;
    padding: spacing(2, 3);

    &Footer {
        display: flex;
		align-items: center;
        justify-content: space-between;
        margin-top: spacing(1);

		&Action {
			display: flex;
			gap: spacing(2);
		}

        &Clear {
			cursor: pointer;
        }
    }
}

.form {
	flex: 1;
	align-self: center;

    border-radius: 16px;

	&Open {
		width: 100%;
		max-height: calc(100vh - 100px);

		overflow-y: auto;

		scrollbar-width: none;
	
		&::-webkit-scrollbar {
			display: none;
		}
	}

	&Wrapper {
		padding: 16px 24px 12px;

		border-top-left-radius: 16px;
		border-top-right-radius: 16px;

		background: white;

		&Open {
			position: sticky;
			top: 0;
			z-index: 3;

			width: 100%;
		}
	}

	&Title {
		display: flex;
		gap: spacing(1);
		align-items: center;
	}

	&Section {
		flex: 1;
		margin-bottom: spacing(1);

		:global(.ant-checkbox-wrapper) {
			padding-left: spacing(2);
		}

		:global(.ant-radio-wrapper) {
			padding-left: spacing(2);
		}

		:global(.ant-input) {
			padding-top: spacing(1);
			padding-bottom: spacing(1);
		}

		:global(.ant-form-item) {
			margin-bottom: 0;
		}

		:global(.ant-form-item-no-colon) {
			font-weight: 500;
			font-size: 15px !important;
			font-family: Inter, sans-serif;
			line-height: 120%;
		}

		&Checkbox {
			padding: spacing(0, 4, 1, 4);
		}
	}
}

.section {
	padding: 16px 2px 0;
}

.row {
	display: flex;
	gap: spacing(1);
}

.inline {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.button {
	display: flex;
	align-items: center;
	justify-content: center;

	height: 46px;

	padding: spacing(1, 2);

	border-radius: 8px;
	
	text-align: center;

	background-color: $c-blue-new;

	cursor: pointer;

	&:hover {
		background: linear-gradient(314.69deg, #4ee036 -205.1%, #89d5ff -52.35%, #b6aaff 110.31%);
	}

	&Disabled {
		cursor: default;

		&:hover {
			background: $c-blue-new;
		}
	}
}

.filters {
	z-index: 2;

	display: flex;
	gap: spacing(1);
	align-items: center;
	margin-top: spacing(-1);
	padding: spacing(1, 1.5);

    cursor: pointer;

	&:hover {
		border-radius: 8px;

		background: #D3E5FF;
	}

	&Wrapper {
		display: flex;
		justify-content: center;

		padding: 12px 16px;

		border-radius: 16px;

		background: #ECF4FF;

		cursor: pointer;

		&Open {
			position: sticky;
			top: 193px;
			z-index: 3;

			width: 100%;

			border-bottom: 1px solid $c-lighter-grey;
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;

			background: white;

			transition: max-height 0.3s ease-in-out;
		}
	}

	&Icon {
		color: $c-blue-new;

		&Open {
			transform: rotate(180deg);
		}
	}
}

.open {
	flex-grow: 1;
  	overflow-y: auto;
}

.modal {
	position: fixed;
	top: 0;

    :global(.ant-modal-content) {
        border: 1px solid $c-blue-new;
        border-radius: 16px;
    }
}
