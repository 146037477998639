@import 'shared/styles/global';

.ant-input {
	height: 32px;
	padding: 0 16px;

	border-color: $c-grey;
	border-radius: 1px;

	font-size: 14px;
	font-family: $f-inter;

	caret-color: $c-dark-green;
}

.ant-input:hover {
	border-color: $c-dark-green;
}

.ant-input:focus {
	border-color: $c-dark-green;

	box-shadow: 0 0 0 2px $c-dark-green-20;
}

.ant-input-lg {
	height: 48px;
}

.ant-input-affix-wrapper,
.ant-input-affix-wrapper-lg {
	padding: 0 16px;

	border-color: $c-grey;
	border-radius: 1px;

	font-size: 14px;
	font-family: $f-inter;

	.ant-input {
		height: 30px;
	}

	.ant-input-lg {
		height: 46px;
	}

	&-status-error {
		border-color: $c-red-error !important;

		box-shadow: 0 0 0 2px $c-red-error-20;
	}
}

.ant-input-affix-wrapper:hover {
	border-color: $c-dark-green !important;
}

.ant-input-affix-wrapper-focused {
	border-color: $c-dark-green;

	box-shadow: 0 0 0 2px $c-dark-green-20;
}
