@import 'shared/styles/global';

$c-button-disabled: #999;

.login {
	display: flex;
	flex-direction: column;
	align-items: center;
	align-self: center;

	width: 50%;
	min-width: 300px;

	padding-top: min(spacing(5.5), 5%);
	padding-bottom: min(spacing(5.5), 5%);

	text-align: center;

	&Hint {
		a {
			font-weight: 700;
			text-decoration: underline;
		}
	}
}

.card {
	display: flex;
	flex-direction: column;
	gap: spacing(5);

	margin-bottom: spacing(5);
	
	&Top {
		display: flex;
		flex-direction: column;
		gap: spacing(1.5);

		text-align: start;

		p {
			color: #5B5B5B !important;
		}
	}

	&Body {
		display: flex;
		flex-direction: column;
		gap: spacing(2);

	}
}

.signIn {
	&Button {
		position: relative;

		display: flex;

		gap: spacing(2);

		align-items: center;

		justify-content: center;

		width: 100%;
		height: 48px;

		border-radius: 8px;

		color: white;

		font-family: $f-inter;

		text-decoration: none;

		background: black;

		&:disabled {
			border-color: $c-button-disabled;

			color: $c-button-disabled;

			filter: grayscale(1);
		}

		&:hover {
			background: #1E78FF;
		}
	}
}

.logo {
	z-index: 100;

	align-self: flex-start;

	cursor: pointer;
}

.input {
	border: 1px solid var(#D9D9D9);
	border-radius: 8px;

	background: #FFF;
}
