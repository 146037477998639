@import "shared/styles/global";

$controls-height: 206px;

.title {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: spacing(2, 3);
	
	&Left, &Right {
		display: flex;
		gap: spacing(2);
		align-items: center;
		min-width: 350px;
	}

	&Right {
		justify-content: flex-end;
	}
}

.controls {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: spacing(2, 3);

	&Left {
		display: flex;
		gap: spacing(2);
		align-items: center;

		min-width: 200px;

		&First {
			margin-right: spacing(2);
		}
	}
}

.header {
	display: flex;
	padding: 10px 0 10px 472px;

	&Middle {
		margin-right: 66px;
		margin-left: 50px;
	}
}

.skeleton {
	height: calc(100vh - $controls-height);
	padding: spacing(2, 1.5);
	overflow: hidden;

	&Row {
		display: flex;
		gap: 75.5px;
		padding: spacing(2);
		border-bottom: solid 1px #f3f3f3;
	}

	&Info {
		display: flex;
		gap: spacing(2);
		min-width: 370px;
	}

	&Text {
		width: 100%;
	}

	&Checkbox {
		min-width: 20px;
		height: 20px;
		border: solid 2px #f3f3f3;
		border-radius: 5px;
	}

	&Data {
		display: flex;
		gap: spacing(1);
		margin-bottom: spacing(1);
	}

	&Cell {
		margin-top: spacing(3);
	}
}
