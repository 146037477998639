.tr {
	position: relative;

	display: flex;
}

.button {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
}
