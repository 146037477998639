@import 'shared/styles/global';

.wrapper {
	height: fit-content;
	margin-right: spacing(3);
	padding: spacing(0, 2, 2);

	border: 1px solid #F0F0F0;
	border-radius: 8px;

	background: #FFF;
	box-shadow: 0 4px 20px 0 rgba(208, 208, 208, 0.16);
}

.title {
	padding: 12px 0;
	border-bottom: solid 1px $c-lighter-grey;

	&Inline {
		display: flex;
		gap: 10px;
	}
}

.open {
	margin: spacing(0, 2, 3, 2);

	font-weight: 500;
}

.more {
	display: flex;
	justify-content: center;
	margin-top: spacing(1);
}

.section {
	display: flex;
	gap: spacing(2);
	padding: spacing(3, 2);

	&Skills {
		flex-direction: column;
	}

	&Programming {
		flex-wrap: wrap;
		padding: spacing(3, 2, 2, 2);
	}
}

.skill{
	&Info {
		display: flex;
		justify-content: space-between;
		margin-bottom: 8px;

		font-weight: 500;
	}
}

.progress{
	height: inherit;
	border-radius: 1px;

	background: $c-success;

	&Bar {
		height: 10px;
		border-radius: 1px;

		background: $c-lighter-grey;
	}
}
