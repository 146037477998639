@import 'shared/styles/global';

.tag {
	display: flex;
	gap: 4px;
	align-items: center;
	padding: 2px 2px 2px 6px;

	border: 1px solid rgba(30, 120, 255, 0.24);
	border-radius: 4px;

	background: #FFF;

	&Full {
		gap: 0;
		padding: spacing(0.5, 1.5);

		border: 1px solid rgba(30, 120, 255, 0.24);
		border-radius: 2px;

		background: #ECF4FF;
	}

	&Close {
		color: $c-blue-new;

		cursor: pointer;
	}
}
