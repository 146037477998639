@import "shared/styles/global";

.award {
	display: flex;
	gap: spacing(1);
	align-items: center;

	&Icon {
		color: $c-dark-grey;
	}
}
