.layout {
	display: grid;
	grid-template:
		'sidebar content' auto;
	grid-template-columns: 180px auto;
}

.sidebar {
	position: fixed;
	top: 0;

	grid-area: sidebar;
}

.content {
	grid-area: content;
}
