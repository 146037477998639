@import 'shared/styles/global';

.tag {
	display: flex;
	align-items: center;
	padding: spacing(0.5, 1.5);
	border: 1px solid rgba(30, 120, 255, 0.24);
	border-radius: 2px;

	font-weight: 500;

	background: #ECF4FF;

	&Inverted {
		border: 1px solid #D3E5FF;

		background: white;

		cursor: pointer;
	}

	button {
		height: 20px;

		color: $c-blue-new;
	}
}
