@import "shared/styles/global";

.button {
	padding: spacing(1);

	color: $c-dark-grey;

	&:disabled {
		background: transparent;
	}
}

.body {
	display: flex;
	flex-direction: column;
	gap: spacing(2);
}
