@import "shared/styles/global";

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 0;

	&Icon {
		padding: 1px;
	}

	div, button {
		display: flex;
		gap: spacing(1);
		align-items: center;
		height: 22px;
	}
}

.body {
	display: flex;
	flex-direction: column;
	gap: spacing(1);

	$width-left-panel: 360px;
	$width-right-panel: 327px;
	$width-margins: 76px;
	width: calc(100vw - $width-left-panel - $width-right-panel - $width-margins);
	padding: spacing(1, 0, 3, 2);

	&Shared {
		display: flex;
		flex-direction: column;
		gap: spacing(1);

		width: calc(1075px - $width-right-panel - $width-margins);
		padding: spacing(1, 0, 3, 2);
	}
}

.icon {
	transition: $transition;
	transition-property: transform;

	&Hide {
		transform: rotate(180deg);
	}

	&Lock {
		margin-bottom: 2px;

		color: $c-dark-grey;
	}
}

.row {
	&:not(:last-child) {
		padding-bottom: spacing(2);
	}
}

.locked {
	display: flex;

	cursor: pointer;
}

.tooltip {
	padding: spacing(1.5);
	border: solid .5px rgba(218, 218, 218, 0.8);
	border-radius: 2px;
}

.label {
	&Tooltip {
		z-index: 10002;

		max-width: 320px;
	}
}