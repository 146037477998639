.github {
	display: flex;
	align-items: center;

	&Logo {
		margin-left: 12px;

		opacity: .5;
	}
}
