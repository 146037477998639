@import "shared/styles/global";

.logo {
	position: absolute;
	top: 44px;
	left: 44px;

	cursor: pointer;
}

.empty {
	gap: spacing(5);
	height: 100vh;

	color: transparent;
}

.body {
    display: flex;
    flex-direction: column;

    gap: spacing(2);
}

.column {
    display: flex;
    flex-direction: column;
}

.text {
	max-width: 400px;

    text-align: start;
}

.link {
	color: #182C4C;
}
