@import "shared/styles/global";

.nav {
	display: flex;
	flex-direction: column;
	border-right: solid 1px $c-grey;

	a {
		padding: spacing(2, 3);

		text-decoration: none;
	}
}
