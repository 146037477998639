@import 'shared/styles/global';

.search {
	display: flex;
	gap: spacing(2);
	align-items: flex-start;
	min-height: 72px;
	padding: spacing(2);
	border: 1px solid $c-grey;
    border-radius: spacing(1);

	background: $c-light-grey;

	&Open {
		background: white;
	}

	&Focus {
		border-color: $c-grey;
	}

	&Icon {
		min-width: 24px;
		margin-top: 3px;

		color: #494949;
	}

	&Input {
		width: 100%;
		height: fit-content;

		max-height: 400px;

		font-size: 14px;
		font-family: $f-inter;
		line-height: 120%;

		resize: none;

		&Small {
			max-height: 36px;

			overflow-y: hidden;
		}

        &::placeholder {
            color: #494949;
        }
	}
}

.wrapper {
	flex: 1;
	padding-top: 4px;
}
