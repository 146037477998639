@import 'shared/styles/global';

.wrapper {
    display: inline-flex;
    gap: spacing(1);
    align-items: center;

    padding: spacing(0.75, 1);

    border-radius: 4px;

    background-color: #CCEDFF;
}

.highlight {
	display: inline-flex;
	gap: spacing(1);
	align-items: center;

	padding: spacing(0.5, 1, 0.5, 0.5);

	border-radius: 4px;

	&Match {
		background: $c-dark-light-green;
	}

	&Likely {
		z-index: 5;

		background-color: #E5E0FF;

		cursor: pointer;
	}

	&Percentage {
		height: 22px;
		padding: 2px 4px;

		border-radius: 2px;

		background: #855CF7;
	}
}

.emoji {
	display: flex;

	align-items: center;

	justify-content: center;
	width: 20px;
	height: 16px;

	font-size: 24px;
}

.tooltip {
    z-index: 10002;

    max-width: 340px;

	&Title {
		padding: spacing(1.5);
		border: solid .5px rgba(218, 218, 218, 0.8);
		border-radius: 2px;
	}
}