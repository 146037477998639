@import "shared/styles/global";

.notification {
	display: flex;
    align-items: center;
    justify-content: space-between;
	padding: spacing(2, 2, 2, 3);

	&Button {
		height: spacing(3);

		color: white;
	}

    &Text {
        margin-top: -3px;
        margin-right: 90px;
    }
}

.row {
    display: flex;
    align-items: center;

    &Credits {
        margin-right: spacing(3);
        margin-left: spacing(0.5);
    }

    &Icon {
        display: flex;

        --webkit-filter: invert(100%);
        filter: invert(100%);
    }
}
