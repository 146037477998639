@import 'shared/styles/global';

.skills {
	display: flex;
	flex-wrap: wrap;
	gap: spacing(1);
}

.tag {
	padding: spacing(0.5, 1);
	border-radius: 1px;
}

.skill {
	background: $c-lighter-grey;
}

.language {
	background: $c-lighter-grey;
}

.match {
	display: inline-flex;
	align-items: center;
	padding: spacing(0, 1);
	border-radius: 2px;

	background: $c-dark-light-green;;
}