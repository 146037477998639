@import "shared/styles/global";

.body {
	display: flex;
	flex-direction: column;
	gap: spacing(2);
}

.project {
	display: flex;
	flex-direction: column;
	gap: spacing(0.5);

	&Top {
		display: flex;
		gap: spacing(1);
		align-items: center;
	}

	&Bottom {
		margin-left: 28px;
	}

	&Icon {
		color: $c-dark-grey;
	}
}


.count {
	display: flex;
	gap: spacing(1);
	align-items: center;

	&Margin {
		margin-top: spacing(-3);
	}

	&Icon {
		color: $c-dark-grey;
	}

	&Error {
		margin-top: spacing(-2);
	}
}
