@import "shared/styles/global";

.popup {
	width: fit-content;
	padding: 0;
	border-radius: 4px;

	background: #242424;
	box-shadow: $c-shadow;

	div {
		margin: 0 auto !important;
	}

	a {
		display: none !important;
	}
}
