@import 'shared/styles/global';

.profile {
	&Info {
		position: relative;

		display: flex;
		gap: spacing(2);
		align-items: center;
		padding-bottom: spacing(1.5);
		padding-left: spacing(1);
	}

	&Account {
		display: flex;
		gap: 8px;
		align-items: center;

		cursor: pointer;

		&Overlay {
			z-index: 10001;
		}

		&Dropdown {
			color: $c-grey;
		}
	}
}

.link {
	text-decoration: none;
}

.gradientBox {
	border: solid 1px transparent;
	border-radius: 50%;

	&Before {
		margin: -1px;

		background: $c-dark-grey;
	}
}

.text {
	&:hover {
		color: white
	}
}