@import 'shared/styles/global';

.ant-dropdown-menu {
	padding: 0 !important;
	border: 1px solid $c-grey !important;
	border-radius: 1px !important;

	color: black !important;

	font-family: $f-inter !important;

	box-shadow: $c-shadow !important;

	li {
		min-width: 170px;
		padding: 8px !important;
		border-bottom: 1px solid $c-grey;
		border-radius: 0 !important;

		font-size: 14px !important;
		line-height: 120% !important;
	}
}
