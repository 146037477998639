@import 'shared/styles/global';

.title {
	display: flex;
	justify-content: space-between;
	margin-bottom: spacing(1);
}

.url {
	margin-left: spacing(1);

	color: $c-dark-grey;

	&:hover {
		color: $c-dark-grey;
	}
}
