@import 'shared/styles/global';

.button {
    display: flex;
    align-items: flex-start;
    padding: 4px 8px;

    border-radius: 2px;

    background: #2A312E;

    cursor: pointer;
}
