@import "shared/styles/global";

.label {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	min-height: 24px;

	&Tag {
		display: flex;
		align-items: center;
		padding: 1px 4px 1px 8px;
		border: solid 1px $c-blue-new;
		border-radius: 1px;

		button {
			height: 20px;

			color: $c-blue-new;
		}

		&Github {
			border: solid 1px #6A55F1 !important;

			button {
				color: #6A55F1 !important;
			}
		}

		&Stackoverflow {
			border: solid 1px rgba(255, 176, 107, 0.60) !important;

			button {
				color: #FF810F !important;
			}
		}
	}
}

// .wrapper {
// 	flex: 1;
// 	padding: spacing(2, 4, 1, 4);
// }

.select {
	margin-bottom: 0;

	:global(.ant-select-selection-placeholder) {
		padding-left: 8px;
	}

	:global(.ant-select-selector) {
		padding-left: 16px;
	}
}

.similar {
	display: flex;
	flex-wrap: wrap;
	gap: spacing(1);
	margin-top: spacing(1);

	&Skill {
		display: flex;
		align-items: center;
		padding: 4px 8px 4px 0;

		&Icon {
			color: $c-green;
		}
	}
}
