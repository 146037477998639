@import "shared/styles/global";

.edit {
	display: flex;
	gap: 10px;
	align-items: center;

	& > * {
		transition: $transition;
	}

	&:hover > * {
		color: $c-dark-green;
	}

	&Input {
		margin-top: 2px;

		& > input {
			font-weight: 700;
			font-family: $f-inter;
		}
	}

	&Text {
		padding-top: 3px;

		text-transform: uppercase;
	}

	&Icon {
		color: $c-dark-grey;
	}
}
