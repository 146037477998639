@import 'shared/styles/global';

$c-button-disabled: #999;

.container {
	position: relative;

    display: grid;
    grid-template-columns: 1fr 1fr;
}

.image {
	height: 100vh;

	background: url('../../../shared/assets/images/login-bg.webp') center no-repeat;

	background-color: #89D5FF;

	background-size: contain;
}

.login {
	display: flex;
	flex-direction: column;
	align-items: center;
	align-self: center;
	justify-content: space-between;

	height: 100%;

	text-align: center;

	&Wrapper {
		display: flex;
		justify-content: center;

		padding: 136px 93px;
	}

	&Button {
		position: relative;

		height: 46px;

		margin-bottom: 20px;
		padding: 13px 89px 13px 94px;

		border: 1px solid black;
		border-radius: 10px;

		font-family: $f-inter;

		text-decoration: none;

		&Icon {
			position: absolute;
			top: 13px;
			left: 24px;
		}

		&:disabled {
			border-color: $c-button-disabled;

			color: $c-button-disabled;

			filter: grayscale(1);
		}
	}

	&Description {
		margin-bottom: 60px;

		span {
			font-weight: 700;
		}
	}

	&Hint {
		max-width: 282px;
		margin: 0 auto;

		a {
			font-weight: 700;
			text-decoration: underline;
		}
	}
}

.form {
	width: 326px;
	margin-bottom: spacing(1);
}
