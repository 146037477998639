@import 'shared/styles/global';

.wrapper {
	flex: 1;
	align-self: center;

    padding: 16px;

	border-radius: 8px;

    background: linear-gradient(0deg, #ECF4FF -50%, #DDD7FF 306.58%);

    box-shadow: 0 1px 5px -1px rgba(172, 172, 172, 0.40);

    &Stackoverflow {
        background: linear-gradient(0deg, #FFFBF7 -50%, #FFE2C8 306.58%);
    }
}

.field {
	padding: 16px 0 8px;

    &First {
        padding-top: 0 !important;
    }
}

.label {
	display: flex;
	gap: spacing(1);
	align-items: center;

	&Icon {
		color: #242424;
	}

	&Tooltip {
		z-index: 10002;

		max-width: 320px;
	}
}

.tooltip {
	width: 328px;
	padding: spacing(1.5);
	border: solid .5px rgba(218, 218, 218, 0.8);
	border-radius: 2px;
}

.selectGithub {
    :global(.ant-select-selector) {
        border-color: #B8AEF6 !important;

        background: linear-gradient(0deg, #ECF4FF -50%, #DDD7FF 306.58%);
    }

    :global(.ant-select-selection-placeholder) {
        color: #242424;
    }
}

.selectStackoverflow {
    :global(.ant-select-selector) {
        border-color: #FFD5B0 !important;

        background: linear-gradient(0deg, #FFFBF7 -50%, #FFE2C8 306.58%);
    }

    :global(.ant-select-selection-placeholder) {
        color: #242424;
    }
}
