.layout {
	display: grid;
	grid-template: 'sidebar content';
	grid-template-columns: 350px auto;
}

.sidebar {
	grid-area: sidebar;
}

.content {
	grid-area: content;
}
