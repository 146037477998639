@import "shared/styles/global";

$border-width: 4px;

.links {
	display: flex;
}

.link {
	z-index: 10;

	display: block;
	padding: spacing(2, 3);

	color: $c-dark-grey;

	text-decoration: none;

	&Active {
		margin-bottom: -$border-width;
		border-bottom: $border-width solid transparent;
		border-image: linear-gradient(94.98deg, #4EE036 -26.18%, #89D5FF 126.51%) 1;

		color: black;
	}

	&:hover {
		.linkHover {
			color: black;
		}
	}

	&Hover {
		transition: $transition;
		transition-property: color;
	}
}
