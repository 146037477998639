.ant-radio-wrapper .ant-radio-inner {
	width: 20px;
	height: 20px;

	background-color: white !important;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
	border-width: 4px;
}
