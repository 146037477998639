@import "shared/styles/global";

.selected {
	display: flex;
	flex-direction: column;
	gap: spacing(1.5);

	&Tooltip {
		padding: spacing(1.5);
		border: solid .5px rgba(218, 218, 218, 0.8);
		border-radius: 2px;
	}
	
	&Border {
		width: 100%;
		height: 1px;

		background: $c-grey;
	}

	&Person {
		color: $c-dark-grey;
	}

	&Credits {
		color: $c-very-dark-green;
	}

	&Element {
		display: flex;
		justify-content: space-between;

		div {
			display: flex;
			gap: spacing(1);
			align-items: center;
		}
	}
}

.label {
	display: flex;
	gap: spacing(1);
	align-items: center;

	&Icon {
		color: $c-dark-grey;
	}

	&Tooltip {
		z-index: 10002;

		max-width: 320px;
	}
}
