@import 'shared/styles/global';

.tooltip {
	padding: spacing(1.5);
	border: solid .5px rgba(218, 218, 218, 0.8);
	border-radius: 2px;

	&Bold {
		font-weight: 700;
	}
}

.label {
    z-index: 10002;

    max-width: 340px;
}
