@import "shared/styles/global";

.avatar {
	border: none;

	color: $c-green;
	font-weight: 500;
	font-size: 24px;
	font-family: $f-space-grotesk;
	line-height: 120%;

	background: #f2f2f2;
}
