@import "shared/styles/global";

.tabs {
	display: flex;
	border-radius: 2px;
	overflow: hidden;

	background-color: $c-very-light-green;
}

.tab {
	padding: 15px 34px;

	font-weight: 600;
	font-size: 15px;
	font-family: $f-inter;
	line-height: 120%;

	&Active {
		z-index: 10;

		background: linear-gradient(94.98deg, #4ee036 -26.18%, #89d5ff 126.51%);
		box-shadow: 0 1px 5px -1px rgba(86, 86, 86, 0.4);
	}

	&:not(.tabActive):hover {
		background: $c-light-green;
	}
}
