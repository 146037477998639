@import 'shared/styles/global';

.container {
	display: flex;
	padding: 0 20px;

	background: white;
}

.wrapper {
	display: flex;
	flex-direction: column;
	gap: 4px;
	align-items: flex-start;
	align-self: stretch;
	padding: 8px;

	border: 1px solid #F2F2F2;
	border-radius: 12px;

	&Title {
		padding: 8px;
	}
}

.navigation {
	display: flex;
	flex-direction: column;
	gap: 4px;
	width: 240px;
	padding: 4px 0 13px;
}

.tab {
	display: flex;
	flex-direction: column;
	gap: 8px;

	padding: 13px 16px;
	border-radius: 8px;

	cursor: pointer;

	&Active {
		background: #ECF4FF;

		span {
			color: #1E78FF;
		}

		&:hover {
			background: #ECF4FF !important;

			.tabSpan {
				color: #1E78FF !important;
			}
		}
	}

	&Border {
		border: 1px solid #F2F2F2;
		border-radius: 8px;

		&:hover {
			.tabSpan {
				color: $c-dark-grey !important;
			}
		}
	}

	&:hover {
		background: #F3F3F3;

		.tabSpan {
			color: black;
		}
	}
}

.socialTab {
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 100%;
	padding: 8px;

	border: 1px solid #F2F2F2;
	border-radius: 8px;

	background: #FFF;

	cursor: pointer;

	&Logo {
		padding: 4px 4px 0;
	}

	&:hover {
		border: 1px solid #F2F2F2;

		background: #F3F3F3;
	}

	&Disabled {
		cursor: default !important;

		&:hover {
			border: 1px solid #F2F2F2 !important;
	
			background: #FFF !important;
		}
	}

	&Active {
		border: 1px solid rgba(51, 27, 196, 0.16);

		background: #E3DFFF;

		&:hover {
			border: 1px solid rgba(51, 27, 196, 0.16);

			background: #E3DFFF;
		}

		&Kaggle {
			border: 1px solid #B4E9FF;

			background: rgba(32, 190, 255, 0.20);

			&:hover {
				border: 1px solid #B4E9FF;

				background: rgba(32, 190, 255, 0.20);
			}
		}

		&Stackoverflow {
			border: 1px solid #FFD5B0;

			background: #FFE2C8;

			&:hover {
				border: 1px solid #FFD5B0;

				background: #FFE2C8;
			}
		}
	}
}

.coming {
    opacity: 0.5;
}

.form {
	flex: 1;
	align-items: flex-start;

	padding: 4px 4px 40px 24px;

	border-radius: 16px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;

	:global(.ant-form-item) {
		margin-bottom: 0;
	}

	&Button {
		width: 226px;

		@include tablet {
			width: auto;
		}
	}

	&Buttons {
		position: sticky;
		bottom: 0;

		display: flex;
		justify-content: space-between;
		padding: spacing(2);
		border-top: 1px solid $c-grey;

		background: white;
	}

	&Last {
		margin-bottom: 120px;
	}
}

.rockstar {
	display: flex;
	align-items: center;

	&Label {
		color: $c-blue-new;
	}
}

.label {
	display: flex;
	gap: spacing(1);
	align-items: center;

	&Icon {
		color: $c-dark-grey;
	}

	&Tooltip {
		z-index: 10002;

		max-width: 320px;
	}
}

.tooltip {
	width: 328px;
	padding: spacing(1.5);
	border: solid .5px rgba(218, 218, 218, 0.8);
	border-radius: 2px;
}

.row {
	display: flex;
	gap: 100px;
	width: 100%;
}

.field {
	padding: 16px 0 8px;
}

.tag {
	display: flex;
	gap: 4px;
	align-items: center;
	padding: 2px 2px 2px 6px;

	border: 1px solid rgba(30, 120, 255, 0.24);
	border-radius: 4px;

	background: #FFF;

	&Close {
		color: $c-blue-new;

		&Big {
			color: $c-dark-grey;
		}
	}

	&All {
		z-index: 2;

		display: flex;
		align-items: center;
		padding: 2px 2px 2px 8px;

		border: 1px solid #D9D9D9;
		border-radius: 4px;

		background: #FFF;

		span {
			color: #2A312E;
		}
	}
}

.inline {
	display: flex;
	gap: 4px;
	align-items: center;

	&Expand {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.tags {
	z-index: 2;

	display: flex;
	flex-wrap: wrap;
	gap: 4px;
}
