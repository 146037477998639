@import "shared/styles/global";

.wrapper {
    flex: 1;
    margin-bottom: spacing(5);
}

.emptyHistory {
    display: flex;
    flex-direction: column;
    gap: spacing(3);
    align-items: center;
    margin: spacing(2, 0);
}

.title {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-left: spacing(2);
}

.card {
    display: flex;
    flex-direction: column;
    padding: spacing(3, 0, 2, 0);

    overflow: hidden;

    transition: $transition;
    transition-property: height;

    &Open {
        height: fit-content;
    }

    &Empty {
        height: fit-content;
    }
}

.section {
    margin-bottom: spacing(2);

    &Header {
        padding: spacing(1, 2);
    }

    &Body {
        display: flex;
        padding: 12px 16px;
    }
}

.link {
    text-decoration: none;

    cursor: pointer;
}

.time {
    width: 100px;
    margin-right: spacing(1);
}

.date {
    width: 140px;
    margin-right: spacing(1);
}

.seeMore {
    margin-top: spacing(2);

    text-align: center;

    &Text {
        padding: spacing(0.25, 0.5);

        cursor: pointer;
    }
}

.label {
	display: flex;
	gap: spacing(1);
	align-items: center;

	&Tooltip {
		z-index: 10002;

		max-width: 320px;
	}
}

.tooltip {
	padding: spacing(1.5);
	border: solid .5px rgba(218, 218, 218, 0.8);
	border-radius: 2px;
}

.items {
    height: 300px;
    min-height: 300px;
}

.text {
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
}

.icon {
    color: $c-dark-grey;
}
