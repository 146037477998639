@import "shared/styles/global";

.body {
	display: flex;
	flex-direction: column;
	gap: spacing(2);
}

.list {
	max-height: 150px;
	overflow-y: scroll;

	&Item {
		display: flex;
		gap: spacing(1);
		align-items: center;

		color: $c-dark-grey;

		&Text {
			padding-top: 1px;
		}

		&:not(:last-child) {
			margin-bottom: spacing(1);
		}
	}
}
