@import './fonts';

@mixin desktop-small {
	@media (max-width: 1439px) {
		@content
	}
}

@mixin laptop {
	@media (max-width: 1239px) {
		@content
	}
}

@mixin tablet {
	@media (max-width: 1023px) {
		@content
	}
}

@mixin mobile {
	@media (max-width: 639px) {
		@content
	}
}
