@import 'shared/styles/colors';

.gradientBox {
	position: relative;

	background: #fff;
	background-clip: padding-box;

	&Before {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;

		border-radius: inherit;

		content: '';
	}
}
