@import "shared/styles/global";

.th {
	display: flex;
	flex: 1;
	align-items: center;
	padding: 14px 0;

	background: $c-very-light-grey;
}
