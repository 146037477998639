.skeleton {
	&Row {
		padding: 16px;
        padding-left: 0;

		border-bottom: solid 1px #f3f3f3;
	}

	&Text {
		width: 100%;
	}

	&Checkbox {
		min-width: 20px;
		height: 20px;
		border: solid 2px #f3f3f3;
		border-radius: 5px;
	}

	&Data {
		display: flex;
		gap: 24px;
		margin-bottom: 8px;
	}
}
