@import "shared/styles/global";

$switch-size: 20px;
$circle-size: 16px;

.background {
	display: flex;
	border-radius: 20px;

	background: $c-grey;
}

.switch {
	position: relative;

	display: inline-block;
	box-sizing: border-box;
	width: $switch-size * 1.83;
	height: $switch-size;
	padding: 0;
	border-radius: 20px;
	overflow: hidden;

	vertical-align: middle;

	background: $c-grey;
	cursor: pointer;

	transition: $transition;

	&::after {
		position: absolute;
		top: 2px;
		left: 2px;

		width: $circle-size;
		height: $circle-size;
		border-radius: 50%;

		background-color: #fff;
		cursor: pointer;

		transition: $transition;

		content: " ";
	}

	&Checked {
		background: linear-gradient(94.98deg, #4EE036 -26.18%, #89D5FF 126.51%);

		&::after {
			left: 18px;
		}
	}
}
