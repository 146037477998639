@import 'shared/styles/global';

.wrapper {
	display: flex;
	gap: spacing(3);
	width: 100%;
	padding: spacing(2, 3);
}

.border {
	position: absolute;
	bottom: 0;
	left: 24px;

	width: calc(100% - 48px);
	height: 1px;

	background-color: $c-lighter-grey;
}

.profile {
	&:hover {
		background: $c-light-grey;
		
		.project, .profileCheckbox {
			visibility: visible;
			opacity: 1;
		}
	}

	&Avatar {
		min-width: 80px;

		cursor: pointer;
	}

	&AvatarBlurred::before {
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100vh;

		backdrop-filter: blur(6px);

		content: "";
	}

	&AvatarBlurred {
		cursor: pointer;

		--webkit-user-select: none;
		--ms-user-select: none;
		user-select: none;
	}

	&Body {
		display: flex;
		flex-direction: column;
		gap: spacing(1);
		height: spacing(5.5);
		margin-bottom: spacing(3);
	}

	&Header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: spacing(3.5);
		margin-bottom: spacing(1);

		&Right {
			display: flex;
			gap: spacing(2);
			align-items: center;
		}
	}

	&Checkbox {
		align-self: start;

		visibility: visible;
		opacity: 1;
	}

	&Name {
		align-items: center;
	}

	&Blurred {
		cursor: pointer;

		filter: blur(6px);

		--webkit-user-select: none;
		--ms-user-select: none;
		user-select: none;
	}

	&TitleWrapper {
		display: flex;
		gap: spacing(1);
	}

	&Title {
		max-width: calc(100vw - 180px - 289px - 48px - 148px - 135px);

		overflow: hidden;

		white-space: nowrap;
		text-overflow: ellipsis;

		opacity: 0.8;

		pointer-events: none;
	}

	&Summary {
		width: 100%;
	}

	&Skills {
		display: flex;
		flex-wrap: wrap;
		gap: spacing(1);
	}

	&Skill {
		padding: spacing(1);
		border-radius: 4px;

		background: #f5f5f5;
	}
}

.row {
	display: flex;
	gap: spacing(1);
	align-items: center;
}

.icon {
	flex-shrink: 0;

	color: $c-grey-profile;
}

.rate {
	display: flex;
	flex-direction: row;
	gap: spacing(0.5);

	padding-left: 16px;

	border-left: 1px solid #D9D9D9;

	&Icon {
		z-index: 5;

		padding: spacing(1);

		cursor: pointer;

		&:hover{
			background-color: #F3F3F3;
		}
	}
}