@import "shared/styles/global";

.title {
	margin-bottom: spacing(4);

	text-align: center;
}

.cards {
	display: flex;
	gap: 20px;
}

.card {
	display: flex;
	flex-basis: 0;
	flex-direction: column;
	flex-grow: 1;
	gap: spacing(3);
	height: fit-content;
	padding: spacing(3);
	border-radius: 4px;

	box-shadow: 0 2px 28px rgba(black, 0.1);

	&Logo {
		width: fit-content;
		height: 24px;
	}

	&Info {
		display: flex;

		div {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
		}
	}

	&Avatar {
		margin-right: spacing(3);
		border-radius: 4px !important;
	}
}
