@import "shared/styles/global";

.empty {
	color: transparent;
}

.title {
	margin-top: -45px;
}

.text {
	max-width: 369px;
	margin-bottom: spacing(3);
}
