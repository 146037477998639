@import 'shared/styles/global';

.wrapper {
	display: flex;
	margin-bottom: spacing(1);
}

.border {
	position: absolute;
	bottom: 0;

	width: 100%;
	height: 1px;

	background-color: $c-lighter-grey;
}

.profile {
	flex-direction: column;
	gap: spacing(1);
	padding: spacing(2, 0);
}

.cell {
    padding-top: spacing(1.5);
	padding-right: spacing(2);

	overflow: hidden;
}
  
.title {
    display: -webkit-box;

    overflow: hidden;

    line-height: 1.2;

	white-space: normal;
	text-overflow: ellipsis;

	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

.download {
    display: flex;
    gap: spacing(1);
    align-items: center;
    width: fit-content;

    margin-top: spacing(-1.5);

    padding: spacing(1);

    border-radius: 8px;

    &Completed {
        background-color: $c-light-green;
        cursor: pointer;
    }

    &Failed {
        background-color: $c-light-red;
    }

    &Pending {
        background-color: $c-grey;
    }
}

.search {
    display: flex;
    gap: spacing(1);
    align-items: center;
    margin-top: spacing(-0.25);

    cursor: pointer;
}

.span {
    font-weight: 700;
}
