.empty {
	display: flex;
	flex-direction: column;
	gap: 12px;
	align-items: center;
	justify-content: center;
	height: 100%;

	text-align: center;
}
