@import "shared/styles/global";

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.spin {
	color: $c-very-dark-green;

	animation: spin 1.5s linear infinite;
}

.button {
	height: 36px;
	padding: spacing(1, 3);

	border-radius: 2px;

	color: black;

	font-weight: 600;
	font-size: 15px;
	font-family: $f-inter;
	line-height: 120%;
	white-space: nowrap;

	background: white;

	&Flex {
		display: flex;
		gap: spacing(1);
		align-items: center;
		justify-content: center;
	}

	&:disabled {
		color: $c-dark-grey;

		background: linear-gradient(94.98deg, #f1f1f1 -26.18%, #e4e4e4 126.51%);

		pointer-events: none;
	}
}

.block {
	width: 100%;
}

.link {
	text-align: center;
	text-decoration: none;

	&:hover {
		color: black;
	}

	&:active {
		color: black;
	}
}

.type_default {
	border: solid 1px $c-grey;

	&:hover {
		background-color: $c-very-light-grey;
	}

	&:active {
		background-color: #e7e7e7;
	}

	&:focus-visible {
		outline: solid 2px $c-bright-green;
	}

	&:disabled {
		border: none;
	}
}

.type_danger {
	background: #f54245;

	&:hover {
		background: #e9595b
	}

	&:active {
		background: #e33537;
	}

	&:focus-visible {
		outline: solid 2px rgba(137, 213, 255, 0.8);
	}
}

.type_primary {
	background: linear-gradient(94.98deg, #4ee036 -26.18%, #89d5ff 126.51%);

	&:hover {
		background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), linear-gradient(94.98deg, #4ee036 -26.18%, #89d5ff 126.51%);
	}

	&:active {
		background: linear-gradient(94.98deg, #aaff9c -26.18%, #1daeff 126.51%);
	}

	&:focus-visible {
		outline: solid 2px rgba(123, 101, 255, 0.6);
	}
}

.type_secondary {
	background: linear-gradient(274.2deg, #4ee036 -101.07%, #89d5ff 8.44%, #b6aaff 125.07%);

	&:hover {
		background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), linear-gradient(274.2deg, #4ee036 -101.07%, #89d5ff 8.44%, #b6aaff 125.07%);
	}

	&:active {
		background: linear-gradient(314.69deg, #4ee036 -205.1%, #89d5ff -52.35%, #b6aaff 110.31%);
	}

	&:focus-visible {
		outline: solid 2px rgba($c-bright-green, 0.6);
	}
}

.type_text {
	padding: 0;

	color: $c-dark-grey;
	font-weight: 500;
	font-size: 14px;

	background: transparent;
}

.type_ghost {
	border: none;

	background-color: transparent;

	&:hover {
		background-color: $c-very-light-grey;
	}

	&:active {
		background-color: #e7e7e7;
	}

	&:focus-visible {
		outline: solid 2px $c-bright-green;
	}

	&:disabled {
		border: none;
	}
}

.size_small {
	height: 33px;
	padding: spacing(0, 3);
}

.size_large {
	height: 56px;
	padding: 18px;
}
