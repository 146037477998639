@import 'shared/styles/global';

.card {
	border: 1px solid $c-lighter-grey;
	border-radius: 4px;

	transition: $transition;
	transition-property: box-shadow;

	&Open,
	&:hover {
		box-shadow: $box-shadow-s;
	}
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: spacing(3);

	&Left {
		display: flex;
		gap: spacing(3);
		align-items: center;
	}

	&Title {
		margin-bottom: spacing(1);
	}
}
