@import 'shared/styles/global';

.tag {
	display: flex;
	align-items: center;
	padding: spacing(0.5, 0.5, 0.5, 1);
	border-radius: 2px;

	font-weight: 500;

	background: #D3E5FF;

	button {
		height: 20px;

		color: $c-blue-new;
	}
}
