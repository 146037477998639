@import "shared/styles/global";


.skeleton {
	min-width: 417px;
	overflow: hidden;
}

.section {
	padding: spacing(1, 4);
}

.firstRow {
	display: flex;
	gap: spacing(1);
	margin-bottom: 7px;
}

.secondRow {
	display: flex;
	gap: spacing(1);
	margin-left: 31px;
}

.number {
	margin-right: spacing(0.5);
}