@import "shared/styles/global";

.logo {
	position: absolute;
	top: 47px;
	left: 41px;
}

.empty {
	gap: spacing(5);
	height: 100vh;

	color: transparent;
}

.text {
	max-width: 250px;
}
