@import "shared/styles/global";

.button {
	display: flex;
	justify-content: flex-start;

	&Medium {
		font-weight: 500;
	}
}

.text {
	white-space: pre-wrap;

	&Color {
		color: $c-grey-profile;
	}

	&Hide {
		overflow-x: hidden;

		white-space: nowrap;
		text-overflow: ellipsis;
	}
}
