@import "shared/styles/global";

.container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100vh;
	height: 100dvh;
	padding: spacing(4, 2, 2);
}

.main {
	text-align: center;
}

.header {
	display: flex;
	align-items: center;
	height: 65px;
}
