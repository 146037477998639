@import 'shared/styles/global';

.root {
	display: flex;
	gap: 10px;
	align-items: flex-start;

	padding: spacing(1, 0.5);
	border-radius: 2px;
}

.green {
	background-color: $c-extra-light-green;
}
