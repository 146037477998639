@import 'shared/styles/global';

.repository {
	display: flex;
	flex-direction: column;
	gap: spacing(2);

	&Top {
		display: flex;
		justify-content: space-between;
		margin-bottom: 4px;
	}

	&Link {
		color: $c-light-blue;
		font-weight: 500;
		text-decoration: none;

		&:hover {
			color: $c-light-blue;
			text-decoration: underline;
		}
	}

	&Contributions {
		display: flex;
		flex-wrap: wrap;
		gap: spacing(1);
	}
	
	&Contribution {
		padding: 4px 8px;

		background: $c-lighter-grey;
	}
}
