@import "shared/styles/global";

.container {
	display: flex;
	flex-direction: column;
	height: 100vh;
	margin: 0 90px;
	overflow-y: scroll;

	scrollbar-width: none;
	
	&::-webkit-scrollbar {
		display: none;
	}
}
