@import "shared/styles/global";

.footer {
	justify-content: space-between !important;

	&Buttons {
		display: flex;
		gap: spacing(1);
	}

	&Logout {
		justify-self: start;

		padding-left: spacing(1);

		&Icon {
			color: $c-dark-grey;
		}
	}
}
