.accordion {
	&Trigger {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		padding: 24px;
	}

	&Content {
		margin-top: -8px;
		padding: 0 24px 24px;

		white-space: pre-wrap;
	}

	&Icon {
		flex-shrink: 0;
	}
}
