@import "shared/styles/global";

$c-button-disabled: #999;

.logo {
	position: absolute;
	top: 44px;
	left: 44px;

	cursor: pointer;
}

.empty {
	gap: spacing(5);
	height: 100vh;

	color: transparent;
}

.text {
	width: 100%;

    span {
        color: black;
        font-weight: 700;
    }
}

.button {
    position: relative;

    display: flex;

    gap: spacing(2);

    align-items: center;

    justify-content: center;

    min-width: 171px;
    max-width: 400px;
    height: 48px;

    border-radius: 8px;

    color: white;

    font-family: $f-inter;

    text-decoration: none;

    background: black;

    &:disabled {
        border-color: $c-button-disabled;

        color: $c-button-disabled;

        filter: grayscale(1);
    }

    &:hover {
        background: #1E78FF;
    }
}

.input {
	border: 1px solid var(#D9D9D9);
	border-radius: 8px;

	background: #FFF;
}

.signIn {
	&Button {
		position: relative;

		display: flex;

		gap: spacing(2);

		align-items: center;

		justify-content: center;

		width: 100%;
		height: 48px;

		border-radius: 8px;

		color: white;

		font-family: $f-inter;

		text-decoration: none;

		background: black;

		&:disabled {
			border-color: $c-button-disabled;

			color: $c-button-disabled;

			filter: grayscale(1);
		}

		&:hover {
			background: #1E78FF;
		}
	}
}

.form {
    width: 400px;
}

.link {
	margin-top: spacing(2);

	color: #182C4C;

	&:hover {
		text-decoration: underline;
	}
}
