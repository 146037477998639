@import "shared/styles/global";

.container {
	height: 100vh;

	overflow-y: scroll;
}

.title {
	margin-bottom: spacing(1);
}

.subtitle {
	margin-bottom: spacing(4);
}

.list {
	display: flex;
	flex-direction: column;
	gap: spacing(3);
}
