.skeleton {
	padding: 24px 16px;

	&Row {
		padding: 16px;
		border-bottom: solid 1px #f3f3f3;
	}

	&Top {
		display: flex;
		gap: 75.5px;
		margin-bottom: 8px;
	}

	&Info {
		display: flex;
		gap: 16px;
		min-width: 370px;
	}

	&Text {
		width: 100%;
	}

	&Checkbox {
		min-width: 20px;
		height: 20px;
		border: solid 2px #f3f3f3;
		border-radius: 5px;
	}

	&Data {
		display: flex;
		gap: 8px;
		margin-bottom: 8px;
	}

	&Cell {
		margin-top: 24px;
	}

	&Skills {
		display: flex;
		gap: 8px;
		margin-left: 112px;
	}
}
