@import "shared/styles/global";

$c-button-disabled: #999;

.logo {
	position: absolute;
	top: 44px;
	left: 44px;
}

.empty {
	gap: spacing(5);
	height: 100vh;

	color: transparent;
}

.column {
    display: flex;
    flex-direction: column;
}

.text {
	max-width: 400px;

    text-align: start;
}

.link {
	color: #182C4C;
}

.button {
    position: relative;

    display: flex;

    gap: spacing(2);

    align-items: center;

    justify-content: center;

    min-width: 171px;
    max-width: 400px;
    height: 48px;

    border-radius: 8px;

    color: white;

    font-family: $f-inter;

    text-decoration: none;

    background: black;

    &:disabled {
        border-color: $c-button-disabled;

        color: $c-button-disabled;

        filter: grayscale(1);
    }

    &:hover {
        background: #1E78FF;
    }
}
