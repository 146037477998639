.icon {
	color: black;
}

.select {
	:global(.ant-select-dropdown) {
		z-index: 1;
	}
}

.github {
	border: 1px solid #B8AEF6 !important;

	border-radius: 2px;

	background: #D3CCFF !important;

	span {
		color: #242424 !important;
	}

	button {
		color: #242424 !important;
	}
}

.stackoverflow {
	border: 1px solid rgba(255, 176, 107, 0.60) !important;

	border-radius: 2px;

	background: #FFE2C8 !important;

	span {
		color: #242424 !important;
	}

	button {
		color: #242424 !important;
	}
}
