@import 'shared/styles/global';

.open {
	&Button {
		position: absolute;
		top: spacing(1);
		left: spacing(9);
		z-index: 10000;

		display: none;
		padding: spacing(1);

		color: $c-dark-grey;

		@include laptop {
			display: block;
		}
	}
}

.form {
	&Title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 48px;
		padding: 0 10px 0 16px;
		border-bottom: solid 1px $c-grey;

		span {
			text-transform: uppercase;
		}
	}

	&Height {
		height: calc(100vh - 160px);
		overflow-y: scroll;
	}

	&Item {
		padding: spacing(2);	
		
		:global(.ant-form-item-no-colon) {
			font-weight: 400;
			font-size: 14px !important;
			font-family: Inter, sans-serif;
			line-height: 120%;
		}
		
		:global(.ant-input) {
			padding: 4px 11px;
		}

		:global(.ant-form-item) {
			margin: spacing(0);
		}
	}

	&Field {
		margin-bottom: 0;

		&Checkbox {
			padding: spacing(2);

			transition: $transition;
			transition-property: background-color;

			&:hover {
				background-color: $c-light-grey;
			}
		}

		&:not(:last-child) {
			border-bottom: solid 1px $c-lighter-grey;
		}
	}

	&Container {
		z-index: 100;

		max-width: 300px;

		border-right: 1px solid $c-grey;

		background-color: white;

		@include laptop {
			display: none;
		}

		&Open {
			@include laptop {
				position: absolute;

				display: block;

				box-shadow: 2px 0 16px rgba(14, 3, 83, 0.15);
			}
		}
	}

	&Buttons {
		position: sticky;
		bottom: 0;

		display: flex;
		flex-direction: column;
		gap: spacing(0.5);
		padding: spacing(2);
		border-top: solid 1px $c-grey;

		background: white;
		
		&Filter {
			color: #4244dc;
		}
	}
}

.rockstar {
	width: 289px;
	margin: spacing(1, 0, 0, -2);
	padding: spacing(2, 2, 0);
	border-top: solid 1px $c-lighter-grey;

	&Label {
		margin-bottom: spacing(1);

		cursor: default;
	}
}

.topSchool {
	margin: spacing(0, 0, -3, 0);
}

.likelyToMove {
	display: flex;
	gap: spacing(1);
	margin-bottom: spacing(1);

	cursor: default;

	&Icon {
		color: $c-dark-grey;
	}
}

.checkbox {
	margin-top: spacing(1);
	margin-bottom: 0;

	:global(.ant-form-item) {
		margin-bottom: 0;
	}
}