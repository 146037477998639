.empty {
	color: transparent;

	&Title {
		width: 150px;
	}

	&Description {
		width: 200px;
	}
}
