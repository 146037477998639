@import 'shared/styles/global';

.wrapper {
	height: 100vh;
	overflow-y: auto;

	background: rgba(16, 17, 26, 0.05);
}

.container {
	position: relative;

	padding: 60px 100px 40px;
}

.row {
	display: flex;
	gap: spacing(4);
	margin-top: 48px;
}
