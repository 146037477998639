.button {
	display: flex;
	justify-content: flex-end;
}

.text {
	white-space: pre-wrap;

	&Hide {
		overflow-x: hidden;

		white-space: nowrap;
		text-overflow: ellipsis;
	}
}
