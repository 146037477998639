@import "shared/styles/global";

.label {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	min-height: 24px;

	&Tag {
		display: flex;
		align-items: center;
		padding: 1px 4px 1px 8px;
		border: solid 1px $c-green-40;
		border-radius: 1px;

		button {
			height: 20px;

			color: $c-green;
		}
	}
}

.wrapper {
	position: relative;

	flex: 1;
}

.select {
	margin-bottom: 0;

	:global(.ant-select-selection-placeholder) {
		padding-left: 44px;

        color: #494949;
	}

	:global(.ant-select-selector) {
		padding-left: 52px !important;

		border-color: $c-grey!important;

        border-radius: spacing(1);

		background: $c-light-grey !important;

        box-shadow: none !important;
	}

	&Open {
		:global(.ant-select-selector) {
			background: white !important;
		}
	}
}

.similar {
	display: flex;
	flex-wrap: wrap;
	gap: spacing(1);
	margin-top: spacing(1);

	&Skill {
		display: flex;
		align-items: center;
		padding: 4px 8px 4px 0;

		&Icon {
			color: $c-green;
		}
	}
}

.icon {
    position: absolute;
    top: 14px;
    left: 16px;

    color: #494949;
}