.ant-skeleton-content {
	li {
		width: 100% !important;
		height: 100% !important;
		min-height: 16px;
		border-radius: 2px !important;
	}

	ul {
		height: 100%;
	}
}
