@import 'shared/styles/global';

.wrapper {
	display: flex;
	gap: spacing(3);
	width: 100%;
	padding: spacing(3);
}

.border {
	position: absolute;
	bottom: 0;
	left: 24px;

	width: calc(100% - 48px);
	height: 1px;

	background-color: $c-lighter-grey;
}

.button {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
}

.profile {
	&:hover {
		background: $c-light-grey;

		.project, .profileCheckbox {
			visibility: visible;
			opacity: 1;
		}
	}

	&Avatar {
		min-width: 80px;

		cursor: pointer;
	}

	&AvatarBlurred::before {
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100vh;

		backdrop-filter: blur(6px);

		content: "";
	}

	&AvatarBlurred {
		cursor: pointer;

		--webkit-user-select: none;
		--ms-user-select: none;
		user-select: none;
	}

	&Body {
		display: flex;
		flex-direction: column;
		gap: spacing(1);
		height: spacing(5.5);
	}

	&Header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: spacing(3.5);
		margin-bottom: spacing(1);

		&Right {
			display: flex;
			gap: spacing(1);

			align-items: center;
		}
	}

	&Checkbox {
		align-self: start;

		visibility: visible;
		opacity: 1;
	}

	&Name {
		align-items: center;
	}

	&Blurred {
		cursor: pointer;

		filter: blur(6px);

		--webkit-user-select: none;
		--ms-user-select: none;
		user-select: none;
	}

	&TitleWrapper {
		display: flex;
		gap: spacing(1);
	}

	&Title {
		max-width: calc(100vw - 180px - 349px - 48px - 148px - 126px);

		overflow: hidden;

		white-space: nowrap;
		text-overflow: ellipsis;

		opacity: 0.8;

		pointer-events: none;
	}

	&Summary {
		width: 100%;
	}

	&Footer {
		display: flex;
		justify-content: space-between;

		margin-top: spacing(3);
	}

	&FooterIcons {
		display: flex;
		gap: spacing(1);
	}
}

.contacts {
	&Mail {
		gap: spacing(1);
		margin-left: 76px;

		color: $c-dark-grey;

		&Icon {
			min-width: 20px;
		}
	}

	&Link {
		z-index: 5;

		text-decoration: none;
	}

	&Project {
		z-index: 5;

		display: flex;
		gap: spacing(1);
		align-items: center;
		margin-left: spacing(1);

		color: $c-dark-grey;

		cursor: pointer;
	}
}

.row {
	display: flex;
	gap: spacing(1);
	align-items: center;
}

.icon {
	&Github {
		color: black;

		&:hover, &:active {
			color: black;
		}
	}

	&Linkedin {
		color: $c-linkedin;

		&:hover, &:active {
			color: $c-linkedin;
		}
	}

	&Website {
		color: $c-light-blue;

		&:hover, &:active {
			color: $c-light-blue;
		}
	}

	&Mail {
		color: $c-dark-grey;
	}

	&Location {
		flex-shrink: 0;

		color: $c-grey-profile;
	}
}

.icons {
	display: flex;
	gap: spacing(1);
	align-items: center;

	color: #adadad;
}
