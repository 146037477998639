@import "shared/styles/global";

.pagination {
    &Wrapper {
        position: relative;

        width: 100%;

        text-align: center;
    }

	&Previous {
        position: absolute;
        left: spacing(2.5);

		transform: rotate(90deg);
	}

	&Next {
        position: absolute;
        right: spacing(3);

		transform: rotate(270deg);
	}

	&Button {
		&:disabled {
			color: $c-dark-grey;

			cursor: not-allowed;
			opacity: .1;
		}
	}
}

:global(.ant-pagination-item) {
    width: 40px;
    height: 40px !important;
    padding: 12px;

    border-radius: 4px !important;

    line-height: 120% !important;

    background-color: #CCEDFF;
}

:global(.ant-pagination-item-active) {
    border-color: #CCEDFF !important;

    background-color: #CCEDFF !important;

    span {
        color: #002133 !important;
    }
}
