@import "shared/styles/global";

.card {
	display: flex;
	flex-direction: column;
	gap: spacing(5);

	&Top {
		display: flex;
		flex-direction: column;
		gap: spacing(1);
		min-height: 70px;
	}

	&Description {
		display: flex;
		flex-direction: column;
		gap: 3px;
		min-height: 76px;
	}

	&Perks {
		display: flex;
		flex-direction: column;
		gap: 3px;
		justify-content: flex-end;
		min-height: 110px;
	}

	&Gradient {
		padding: spacing(4, 3);
		border: solid 2px transparent;
		border-radius: 4px;

		&Before {
			margin: -2px;

			background: linear-gradient(
					to bottom right,
					rgba($c-primary-gradient-left, 0.4),
					rgba($c-primary-gradient-right, 0.4)
			);
		}
	}
}
