@import 'shared/styles/colors';
@import 'shared/styles/fonts';

.ant-tooltip-inner {
	font-size: 12px;
	line-height: 120%;
}

.ant-tooltip-placement-rightTop {
	.ant-tooltip-arrow {
		top: -4px !important;

		&::before {
			height: 4px !important;
		}

		&::after {
			display: none;
		}
	}
}

