@import "shared/styles/global";

.body {
	display: flex;
	flex-direction: column;
	gap: spacing(2);
}

.upgrade {
	display: flex;
	justify-content: space-between;
	padding: spacing(0.5, 0.5, 0.5, 1.5);
	border-radius: 2px;

	background: $c-very-light-green;

	div {
		display: flex;
		gap: 10px;
		align-items: center;
	}

	&Link {
		padding: spacing(1, 3);
	}
}

.count {
	display: flex;
	gap: spacing(1);
	align-items: center;
}

.checkbox {
	display: flex;
	align-items: center;
}

.form {
	margin-bottom: spacing(4);
}
