.ant-checkbox-group {
	flex-direction: column;
	gap: 8px;

	.ant-checkbox-wrapper {
		margin: 0;
	}
}

.ant-checkbox-inner {
	border-width: 1.5px !important;
}

.ant-checkbox-input:focus-visible + .ant-checkbox-inner {
	outline-offset: 0 !important;
}
