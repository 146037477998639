@import 'shared/styles/global';

.education {
	display: flex;
	gap: spacing(1);

	&Avatar {
		min-width: 60px;

		background: #dedede;
	}

	&Info {
		display: flex;
		flex-direction: column;
		gap: spacing(1);
	}
}
