@import 'shared/styles/global';

.header {
	position: sticky;
	top: 0;
	z-index: 10;

	width: 100%;
	padding: 12px 0;
	border-bottom: 1px solid $c-grey;

	text-align: center;

	background: white;

	button {
		position: absolute;
		top: 12px;
		right: 12px;
	}
}

.modal {
	top: 20px;

	border-radius: 4px;

	overflow: hidden;
}

.height {
	height: calc(100vh - 158px);
	overflow-y: scroll;
}

.form {
	border-radius: 4px;
	overflow: hidden;
}
