@import 'shared/styles/global';

.experience {
	display: flex;
	gap: spacing(1);

	&Wrapper {
		min-width: 0;
	}

	&Avatar {
		min-width: 60px;

		background: #dedede;
	}

	&Top {
		display: flex;
		gap: spacing(1);
		align-items: center;
		margin-bottom: spacing(1);
	}

	&Current {
		padding: 4px 8px;

		background: $c-dark-light-green;
	}

	&Company {
		margin-bottom: spacing(1);

		&Link {
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	&Info {
		margin-bottom: spacing(2);
	}
}
