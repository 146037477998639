@import "shared/styles/global";

.contacts {
    z-index: 5;

    display: flex;
    gap: spacing(1);

    color: $c-dark-grey;
}

.icon {
	&Github {
		color: black;

		&:hover, &:active {
			color: black;
		}
	}

	&Linkedin {
		color: $c-linkedin;

		&:hover, &:active {
			color: $c-linkedin;
		}
	}

	&Facebook {
		color: $c-facebook;

		&:hover, &:active {
			color: $c-facebook;
		}
	}

	&Twitter {
		color: black;

		&:hover, &:active {
			color: black;
		}
	}

	&Website {
		color: $c-light-blue;

		&:hover, &:active {
			color: $c-light-blue;
		}
	}

	&Mail {
		color: $c-light-blue;

		&:hover, &:active {
			color: $c-light-blue;
		}
	}
}
