.td {
	display: flex;
	flex: 1;

	word-break: break-word;
}

.align_top {
	align-items: flex-start;
}

.align_center {
	align-items: center;
}

.align_bottom {
	align-items: flex-end;
}

.justify_left {
	justify-content: flex-start;
}

.justify_center {
	justify-content: center;
}

.justify_right {
	justify-content: flex-end;
}
