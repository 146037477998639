@import "shared/styles/global";

$c-button-disabled: #999;

.container {
    display: flex;
    height: 655px;
}

.wrapper {
    display: flex;
    flex-direction: column;
	padding: spacing(5.5, 7.5);

    ul {
        display: flex;
        flex-direction: column;
        gap: spacing(2);

        width: 369px;
    
        padding-left: spacing(2);
    
        p {
            color: #5B5B5B !important;
        }
    }
}

.footer {
    display: flex;
    flex-direction: column;
    gap: spacing(2);

    margin-top: auto;

    &Continue {
        padding: spacing(1, 3);

        text-align: center;

        cursor: pointer;
    }    
}

.title {
    display: flex;
    flex-direction: column;

    width: 369px;

    padding-top: spacing(5);
    padding-bottom: spacing(4);
}

.image {
    width: 720px;

    border-top-right-radius: spacing(2);
    border-bottom-right-radius: spacing(2);

    background: url('../../../../shared/assets/images/chrome-extension.webp') center no-repeat;

    background-size: cover;
}

.button {
    position: relative;

    display: flex;

    gap: spacing(2);

    align-items: center;

    justify-content: center;

    width: 100%;
    height: 48px;

    border-radius: 8px;

    font-family: $f-inter;

    text-decoration: none;

    background: black;

    &:disabled {
        border-color: $c-button-disabled;

        color: $c-button-disabled;

        filter: grayscale(1);
    }

    &:hover {
        background: #1E78FF;
    }
}
