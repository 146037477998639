@import "shared/styles/global";

.title {
	margin-bottom: spacing(3);

	text-align: center;
}

.companies {
	display: flex;

	align-items: center;
	
	justify-content: space-between;

	height: 44px;
	
	margin: 0 auto;

	filter: grayscale(1);
}

.company {
	&:first-child {
		height: 38px;
	}

	&:nth-child(2) {
		height: 32px;
	}

	&:nth-child(3) {
		height: 44px;
	}

	&:nth-child(4) {
		height: 34px;
	}

	&:last-child {
		height: 32px;
	}
}
