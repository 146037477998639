@import "shared/styles/global";

.container {
    display: flex;

    height: 467px;
}

.wrapper {
    display: flex;
    flex-direction: column;
	padding: spacing(3, 4);
}

.footer {
    display: flex;
    gap: spacing(1);
    align-self: end;
}

.title {
    display: flex;
    flex-direction: column;
    gap: spacing(2);
    justify-content: center;
    width: 415px;
    height: 395px;
}

.image {
    position: relative;

    width: 420px;

    border-top-right-radius: spacing(1);
    border-bottom-right-radius: spacing(1);

	background: url('../../../../shared/assets/images/modal.webp') center no-repeat;
	background-size: cover;
}

.icon {
    position: absolute;
    top: 10px;
    right: 10px;

    cursor: pointer;
}
