@import "shared/styles/global";

.SearchSelect_add-tag {
	.ant-select-selection-overflow-item {
		&:last-child::after {
			display: flex;
			align-items: center;
			margin-left: -4px;

			color: $c-blue-new;
			font-weight: 500;
			font-size: 14px;
			line-height: 120%;

			content: '+ Add tag';
		}
	}
}

.SearchSelect_add-tag_github {
	.ant-select-selection-overflow-item {
		&:last-child::after {
			color: #6A55F1 !important;
		}
	}
}

.SearchSelect_add-tag_stackoverflow {
	.ant-select-selection-overflow-item {
		&:last-child::after {
			color: #FF810F !important;
		}
	}
}
