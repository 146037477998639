@import 'shared/styles/global';

.ant-select-selection-placeholder {
	inset-inline-start: 8px !important;
}

.ant-select-selection-search {
	margin-inline-start: 0 !important;
}

.ant-select-dropdown {
	z-index: 20000;

	width: 250px;
	min-width: 250px !important;
	padding: 0;

	border: solid 1px $c-grey;

	.ant-select-item {
		align-items: center;
		padding: spacing(1);

		.ant-select-item-option-state {
			height: 20px;
		}
	}
}

.ant-select-item-option-selected {
	&:hover {
		background-color: $c-light-grey !important;
	}
}

.ant-select-selection-overflow {
	gap: 8px;
	padding: 0;
}

.ant-select-multiple.ant-select-lg .ant-select-selector {
	padding: 8px;
}

.ant-select-multiple .ant-select-selector {
	padding: 8px;
}

.rc-virtual-list-holder {
	max-height: 190px !important;
}
