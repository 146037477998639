@import "shared/styles/global";

.modal {
	display: flex;
	flex-direction: column;
	gap: spacing(3);
	padding: spacing(3);

	&Close {
		position: absolute;
		top: 8px;
		right: 8px;

		padding: spacing(1);
	}
}

.header {
	display: flex;
	gap: spacing(3);
	align-items: center;
}

.icon {
	display: inline-flex;
	padding: 6px;
	border-radius: 50%;
}

.icon_color_red {
	color: $c-red-danger;

	background-color: $c-red-danger-20;
}

.icon_color_green {
	color: $c-bright-green;

	background-color: $c-bright-green-20;
}

.icon_color_yellow {
	color: $c-yellow;

	background-color: $c-yellow-20;
}

.icon_color_blue {
	color: $c-light-blue;

	background-color: $c-light-extra-blue;
}
