@import "shared/styles/global";

.button {
	display: flex;
    gap: spacing(1);
    align-items: center;
	width: 100%;
	padding: spacing(1, 2);

	text-align: left;

	transition: $transition;
	transition-property: background-color;

	&:hover {
		background-color: $c-light-grey;
	}
}

.text {
	display: block;
	max-width: 170px;
	overflow: hidden;

	white-space: nowrap;
	text-overflow: ellipsis;
}

.dropdown {
	width: 230px;

	background-color: white;
	box-shadow: 0 3px 30px -5px rgba(160, 160, 160, 0.1), 0 12px 16px -2px rgba(170, 170, 170, 0.25);

	&List {
		max-height: 217px;
		border-top: solid 1px $c-grey;
		overflow: auto;
	}
}
