@import 'shared/styles/global';

.header {
	display: flex;
	gap: spacing(2);
	align-items: center;
}

.logo {
	display: flex;
	align-items: center;

	img {
		width: 32px;
		height: 32px;
	}
}

.summary {
	margin-top: spacing(3);
}

.actions {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.list {
	display: flex;
	flex-direction: column;
	gap: spacing(1);

	list-style: none;
}

.list + .list {
	margin-top: spacing(2);
}
