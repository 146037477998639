@import 'shared/styles/global';

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 6px 0;

	cursor: pointer;

	&Left {
		display: flex;
		gap: spacing(2);
		align-items: flex-start;
	}

	&Title {
		margin-bottom: spacing(1);
	}

	&Button {
		&Open {
			padding-right: spacing(2);
		}

		&Close {
			padding: spacing(1);

			color: black;

			&Opened {
				transform: rotate(180deg);
			}
		}
	}
}

.body {
	display: grid;
	grid-template-rows: 0fr;
	margin-left: 42px;
	padding-bottom: spacing(1);

	visibility: hidden;
	opacity: 0;

	transition: $transition;

	transition-property: grid-template-rows;

	& > div {
		overflow: hidden;
	}

	&Open {
		grid-template-rows: 1fr;
		margin-top: spacing(1);

		visibility: visible;
		opacity: 1;
	}
}
