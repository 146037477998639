@import "shared/styles/global";

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: spacing(11);
	padding: spacing(1, 5);

	color: white;

	background-color: $c-dark-blue;

	a {
		height: 40px;
	}
}
