$width-left-panel: 363px;

.modal {
	top: 52px;

	width: calc(100% - $width-left-panel) !important;
	margin: 0 0 0 auto !important;

	box-shadow: -4px 1px 10px rgba(0, 0, 0, 0.1);
}
