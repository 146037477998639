@import "shared/styles/global";

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.search {
	width: 100%;
	height: 78px;

	& > * {
		pointer-events: none;
	}

	&Container {
		display: flex;
		justify-content: center;
		width: 100%;
		padding: 6px 16px 0;
	}

	&Info {
		display: flex;
		flex-direction: column;
		gap: spacing(1);
		align-items: center;

		color: #1E78FF;

		&Icon {
			min-height: 44px;
		}
	}
}

.small {
	& span {
		padding: 0 !important;
	}

	& > div {
		border: none !important;
	}
	
	&Icon {
		height: 32px;
		padding: spacing(0.5);
		border-radius: 2px;

		color: $c-light-blue;

		background-color: #ddebff;
		cursor: pointer;
	}
}

.spin {
	animation: spin 1.5s linear infinite;
}
