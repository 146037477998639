@import "shared/styles/global";

.text {
	line-height: 120%;
}

.color_black {
	color: black;
}

.color_grey {
	color: $c-dark-grey;
}

.color_light_grey {
	color: #bababa;
}

.color_lighter_grey {
	color: $c-grey-profile;
}

.color_light_blue {
	color: $c-light-blue;
}

.color_blue {
	color: $c-blue;
}

.color_blue_new {
	color: $c-blue-new;
}

.color_dark_blue {
	color: $c-dark-blue;
}

.color_green {
	color: $c-dark-green;
}

.color_dark_green {
	color: $c-very-dark-green;
}

.color_dark_grey {
	color: #5e5e5e;
}

.color_darker_grey {
	color: $c-grey-profile;
}

.color_white {
	color: white;
}

.color_red {
	color: $c-red-error;
}

.color_orange {
	color: $c-dark-orange;
}

.font_inter {
	font-family: $f-inter;
}

.font_grotesk {
	font-family: $f-space-grotesk;
}

.font_mono {
	font-family: $f-space-mono;
}

.weight_bold {
	font-weight: 700;
}

.weight_semi {
	font-weight: 600;
}

.weight_medium {
	font-weight: 500;
}

.weight_regular {
	font-weight: 400;
}

.size_32 {
	font-size: 32px;
}

.size_28 {
	font-size: 28px;
}

.size_26 {
	font-size: 26px;
}

.size_24 {
	font-size: 24px;
}

.size_22 {
	font-size: 22px;
}

.size_20 {
	font-size: 20px;
}

.size_18 {
	font-size: 18px;
}

.size_16 {
	font-size: 16px;
}

.size_15 {
	font-size: 15px;
}

.size_14 {
	font-size: 14px;
}

.size_13 {
	font-size: 13px;
}

.size_12 {
	font-size: 12px;
}
