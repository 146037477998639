@import 'shared/styles/global';

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: spacing(2, 3, 2, 1);
}

.left {
	display: flex;
	gap: spacing(1);
	align-items: center;
}

.back {
	padding: spacing(1);

	transform: rotate(90deg);
}

.actions {
	display: flex;
	gap: spacing(2);
	align-items: center;
}
