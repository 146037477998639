@import 'shared/styles/global';

.search {
	display: flex;
	gap: spacing(2);
	align-items: flex-start;
	min-height: 100px;
	padding: spacing(2);
	border: 1px solid $c-lighter-grey;
	border-radius: 4px;

	background: white;

	box-shadow: $box-shadow-s;

	&Focus {
		border-color: $c-light-blue;
	}

	&Icon {
		min-width: 24px;
		margin-top: 3px;

		color: $c-dark-grey;
	}

	&Input {
		width: 100%;
		height: fit-content;
		max-height: 400px;

		font-size: 16px;
		font-family: $f-inter;
		line-height: 120%;

		resize: none;
	}

	&Close {
		height: 40px;
		padding: spacing(1);

		color: $c-dark-grey;
	}

	&Right {
		margin-left: auto;
	}
}

.wrapper {
	flex: 1;
	padding-top: 6px;
}

.invisible {
	position: absolute;

	width: 0;
	height: 0;

	visibility: hidden;
}
