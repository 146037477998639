@import 'shared/styles/global';

.tooltip {
	width: 328px;
	padding: spacing(1.5);

	border: solid .5px rgba(218, 218, 218, 0.8);
	border-radius: 2px;

	background-color: $c-very-light-grey;

	&Plan {
		margin-top: spacing(2);
	}

	&Upgrade {
		color: $c-success !important;
		text-decoration: underline;

		cursor: pointer;
	}

    &Wrapper {
        z-index: 10002;

		max-width: 320px;
    }
}
