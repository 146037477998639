@import "shared/styles/global";

.button {
	color: $c-dark-grey;
	font-family: $f-inter;
	line-height: 120%;

	&:hover {
		color: $c-success;
	}

	&:active {
		color: $c-dark-green;
	}
}
