@import 'shared/styles/global';

.header {
	display: flex;
	gap: spacing(2);
	align-items: center;
}

.logo {
	display: flex;
	align-items: center;

	img {
		width: 32px;
		height: 32px;
	}
}

.campaigns {
	max-height: 286px;
	margin-top: 10px;
	overflow-y: auto;
}

.campaign {
	cursor: pointer;
}

.campaign + .campaign {
	border-top: 1px solid $c-lighter-grey;
}

.empty {
	margin-top: spacing(3);
}
