@import 'shared/styles/global';

@mixin short {
	@media (max-height: 1000px) {
		@content
	}
}

.form {
	width: 100%;
	margin: 0 auto;

	&Title {
		margin-bottom: spacing(2);
	}

	&Description {
		margin-bottom: 24px;

		text-align: center;

		@include short {
			margin-bottom: 16px;
		}
	}

	&Wrapper {
		padding: spacing(5);

		box-shadow: $c-box-shadow;

		@include short {
			padding: spacing(4);
		}
	}

	&Company {
		margin-bottom: 36px;

		@include short {
			margin-bottom: 16px;
		}
	}
}

.email {
	margin-bottom: 32px;

	@include short {
		margin-bottom: 24px;
	}

	&Title {
		margin-bottom: 8px;

		cursor: default;
	}

	&Link {
		color: $c-dark-grey;
		text-decoration: underline !important;

		&:hover {
			color: $c-dark-grey;
			text-decoration: underline !important;
		}
	}
}

.buttons {
	display: flex;
	flex-direction: column;
	gap: spacing(2);
	align-items: center;
}

$c-button-disabled: #999;

.login {
	display: flex;
	flex-direction: column;
	align-items: center;
	align-self: center;

	width: 50%;
	min-width: 300px;

	padding-top: min(spacing(5.5), 5%);
	padding-bottom: min(spacing(5.5), 5%);

	text-align: center;

	@include short {
		justify-content: center;
		padding-top: 0;
		padding-bottom: 0;
	}

	&Button {
		position: relative;

		display: flex;

		gap: spacing(2);

		align-items: center;

		justify-content: center;

		width: 100%;
		height: 48px;

		border-radius: 8px;

		color: white;

		font-family: $f-inter;

		text-decoration: none;

		background: black;

		&Icon {
			padding: 6px;

			border-radius: 4px;

			background-color: #FFF;
		}

		&:disabled {
			border-color: $c-button-disabled;

			color: $c-button-disabled;

			filter: grayscale(1);
		}

		&:hover {
			background: #1E78FF;
		}
	}
}

.card {
	display: flex;
	flex-direction: column;
	gap: spacing(10);

	width: 100%;
	
	&Top {
		display: flex;
		flex-direction: column;
		gap: spacing(1.5);

		text-align: start;
	}
}

.logo {
	z-index: 100;

	align-self: flex-start;

	cursor: pointer;

	@include short {
		position: absolute;
		top: spacing(2);
		left: spacing(4);
	}
}

.input {
	border: 1px solid var(#D9D9D9);
	border-radius: 8px;

	background: #FFF;
}

.link {
	color: #182C4C;

	&:hover {
		text-decoration: underline;
	}
}
