@import 'shared/styles/global';

.wrapper {
	height: calc(100vh - 54px);
	overflow: auto;
}

.profile {
	display: grid;
	grid-template-columns: 1fr 311px;
	margin-bottom: spacing(8);
}

.info {
	display: flex;
	gap: spacing(3);
	padding: spacing(3);

	&Avatar {
		min-width: 88px;
	}

	&AvatarBlurred::before {
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100vh;

		backdrop-filter: blur(6px);

		content: "";
	}

	&AvatarBlurred {
		cursor: pointer;

		--webkit-user-select: none;
		--ms-user-select: none;
		user-select: none;
	}

	&Name {
		margin-bottom: spacing(1);
	}

	&Title {
		margin-bottom: spacing(1);

		color: $c-grey-profile;

		span {
			color: black;
			font-weight: 500;
		}
	}

	&Summary {
		$width-left-panel: 363px;
		$width-image: 120px;
		width: calc(100% - $width-left-panel - $width-image) !important;
	}

	&Blurred {
		cursor: pointer;

		filter: blur(6px);

		--webkit-user-select: none;
		--ms-user-select: none;
		user-select: none;
	}
}

.sections {
	margin-right: spacing(2);
	margin-left: spacing(3);
	padding: spacing(1, 2, 2);

	border: 1px solid #F0F0F0;
	border-radius: 8px;

	background: #FFF;
	box-shadow: 0 4px 20px 0 rgba(208, 208, 208, 0.16);

	section {
		&:not(:last-child) {
			border-bottom: solid 1px $c-lighter-grey;
		}
	}

	&:not(:last-child) {
		margin-bottom: spacing(2);
	}
}

.highlights {
	display: flex;
	gap: spacing(1);
	margin-bottom: spacing(1);
}

.location {
	display: flex;
	gap: spacing(1);
	align-items: center;

	&Icon {
		flex-shrink: 0;

		color: $c-grey-profile;
	}
}
