@import 'shared/styles/global';

.wrapper {
	height: fit-content;
	margin-right: spacing(3);
	padding: spacing(0, 2, 2);

	border: 1px solid #F0F0F0;
	border-radius: 8px;

	background: #FFF;
	box-shadow: 0 4px 20px 0 rgba(208, 208, 208, 0.16);
}

.title {
    display: flex;
    gap: 10px;
	padding: 12px 0;
	border-bottom: solid 1px $c-lighter-grey;
}

.open {
	margin: spacing(0, 2, 3, 2);

	font-weight: 500;
}

.more {
	display: flex;
	justify-content: center;
	margin-top: spacing(1);
}

.badges {
    display: flex;
    flex-direction: column;
	gap: spacing(1);
    margin-top: spacing(2);
    margin-bottom: spacing(3);

    &List {
        display: flex;
        flex-direction: column;
        gap: spacing(1);
    }
}

.stats {
	display: flex;
    flex-direction: column;
	gap: spacing(1);
    margin-top: spacing(2);

	&Wrapper {
		display: flex;
		justify-content: center;
		width: 100%;
	}

	&Column {
		display: flex;
		flex-direction: column;
		gap: spacing(3);
	}

	&Row {
		display: flex;
		gap: spacing(3);
	}

	&Individual {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

.badge {
    display: flex;
    justify-content: space-between;
	padding: spacing(1);

	border-radius: 4px;

    background: linear-gradient(90deg, #FCEED8 0%, #FEF7EE 100%);

    &Percent {
        display: flex;
        padding-left: 8px;
        border-left: 1px solid rgba(142, 59, 31, 0.2);

        color: #270E03;
        
		font-weight: 500;
		font-size: 14px;
        font-style: normal;
        line-height: 120%;
    }
}

.section {
	display: flex;
	gap: spacing(2);
	padding: spacing(3, 2);

	&Skills {
		flex-direction: column;
	}

	&Programming {
		flex-wrap: wrap;
		padding: spacing(3, 2, 2, 2);
	}
}

.skill{
	&Info {
		display: flex;
		justify-content: space-between;
		margin-bottom: 8px;

		font-weight: 500;
	}
}

.progress{
	height: inherit;
	border-radius: 1px;

	background: $c-success;

	&Bar {
		height: 10px;
		border-radius: 1px;

		background: $c-lighter-grey;
	}
}
