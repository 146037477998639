@import 'shared/styles/global';

$header-height: 190px;

.container {
	overflow: hidden;
}

.info {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: spacing(2, 2, 1.5, 3);

	border-bottom: 1px solid #F1F1F1;

	&Buttons {
		display: flex;
		gap: spacing(1);
	}
}

.header {
	position: sticky;
	top: 0;
	z-index: 10;

	background-color: white;

	&Border{
		border-bottom: 1px solid #D9D9D9;
	}
}

.list {
	height: calc(100vh - $header-height);

	scrollbar-width: none;
	
	&::-webkit-scrollbar {
		display: none;
	}

	&Control {
		display: flex;
		justify-content: space-between;
		
		padding: spacing(2, 3);
	}
}

.project {
	padding: spacing(1,1,1,3);
}

.footer {
	position: relative;

	display: flex;

	align-items: center;

	justify-content: center;

	height: 60px;
	padding: spacing(0, 3);

	&Pagination {
		position: absolute;
		top: 18px;
		right: 84px;

		margin-left: auto;
	}
}