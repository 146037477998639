@import 'shared/styles/global';

.button {
	display: flex;
	gap: spacing(1);
	align-items: center;
	width: 100%;
	padding: spacing(1, 2);

	transition: $transition;
	transition-property: background-color;

	&:hover {
		background-color: $c-light-grey;
	}

	img {
		width: 20px;
		height: 20px;
		margin-right: 10px;
	}
}

.text {
	max-width: 170px;
	overflow: hidden;

	white-space: nowrap;
	text-overflow: ellipsis;
}

.status {
	width: 12px;
	height: 12px;
	margin: 4px;
	border-radius: 50%;

	background-color: $c-dark-grey;

	&_open {
		background-color: #61dd75;
	}
}

.dropdown {
	width: 260px;
	border: 1px  solid $c-lighter-grey;
	border-radius: 2px;

	background-color: white;
	box-shadow: 0 3px 30px -5px rgba(160, 160, 160, 0.1), 0 12px 16px -2px rgba(170, 170, 170, 0.25);
}

.connect {
	margin-left: auto;
	padding: spacing(0.5, 1);
	border: 1px solid $c-lighter-grey;
	border-radius: 1px;

	background-color: $c-lighter-grey;
}
