@import "shared/styles/global";

.header {
	display: flex;
	
	gap: 10px;
	align-items: center;
	padding: spacing(3, 3);
	border-bottom: solid 1px $c-grey;
}
