@import 'shared/styles/global';

.publication {
	display: flex;
	flex-direction: column;
	gap: spacing(1);

	&Title {
		display: flex;
		justify-content: space-between;

		&Margin {
			margin-bottom: spacing(1);
		}

		p {
			overflow-wrap: anywhere;
		}
	}

	&Url {
		margin-left: spacing(1);

		color: $c-dark-grey;

		&:hover {
			color: $c-dark-grey;
		}
	}
}
