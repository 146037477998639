@import 'shared/styles/global';

.pagination {
	display: flex;
	gap: spacing(1);
	align-items: center;

	&Previous {
		transform: rotate(90deg);
	}

	&Next {
		transform: rotate(270deg);
	}

	&Buttons {
		display: flex;
		gap: 8px;
		align-items: center;
	}

	&Button {
		&:disabled {
			color: $c-dark-grey;

			cursor: not-allowed;
			opacity: .1;
		}
	}
}
