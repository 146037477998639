.icon {
	display: inline-block;

	font-size: 0;

	fill: currentColor;

	svg {
		display: block;
		width: 100% !important;
		height: 100% !important;
	}
}
