@import 'shared/styles/global';

.container {
	display: flex;
	flex-direction: column;
	gap: spacing(10);
	max-width: 1388px;
	height: 100vh;
	margin: 0 auto;
	padding: spacing(4, 6);

	overflow-y: scroll;
}
