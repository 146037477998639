@import "shared/styles/global";

.card {
	width: 500px;
	padding: spacing(4) 100px spacing(8);

	box-shadow: $c-box-shadow;
}

.content {
	display: flex;
	flex-direction: column;
	gap: spacing(4);
	width: 300px;
}
