@import "shared/styles/global";

.notification {
	display: flex;
	gap: spacing(1);
	align-items: center;
	max-width: 495px;
	padding: spacing(2, 2, 2, 3);

	&Button {
		height: spacing(3);

		color: white;
	}
}
