@import "shared/styles/global";

.wrapper {
	display: flex;
	gap: spacing(1);
	align-items: center;
}

.input {
	height: 20px;
	border-radius: 2px;

	outline: solid 2px $c-green;

	&Error {
		outline: solid 2px #e9595b;
	}
}

.error {
	color: #c54244;
}

